import React, { useState, useEffect } from 'react'
import Navbar from '../Components/Navbar'
import Create from '../Assets/Images/create.jpg'
import { CircularProgress } from '@mui/material'
import { auth } from '../Firebase'

function ResetPasswordScreen() {

    const [loading, setLoading] = useState(false)

    const [email, setEmail] = useState('')

    const [msg, setMsg] = useState('')
    const [emailError, setEmailError] = useState('')

    const onChangeEmail = (event) => {
        setEmail(event.target.value)
    }

    const submit = (e) => {
        e.preventDefault()
        if (email == '') {
            setEmailError('Email cannot be empty')
        } else {
            setLoading(true)
            auth.sendPasswordResetEmail(email)
                .then(() => {
                    setMsg('A link has been sent to your email')
                    setLoading(false)
                }).catch(e => {
                    console.log(e)
                    setLoading(false)
                })
        }
    }

  return (
    <div style={{ width: '100%' }}>
            <Navbar />
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 100 }}>
                <div>
                    <p style={{ fontSize: 30, fontFamily: 'RobotoBold', color: '#384A62', marginLeft: 20 }}>Forgot Password?</p>
                    <p style={{ fontSize: 18, color: 'green', marginLeft: 20 }}>{msg}</p>
                    <form>
                        <div style={{ marginLeft: 20, marginTop: 50 }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label style={{ color: '#4B5A70', marginBottom: 15, fontSize: 16, fontFamily: 'RobotoBold' }}>Your Email</label>
                                    {emailError == '' ? (
                                        <></>
                                    ): (
                                        <p style={{ fontSize: 18, color: 'red', marginLeft: 20 }}>{msg}</p>
                                    )}
                                    <input
                                        type='email'
                                        placeholder='Enter Your Email'
                                        onChange={onChangeEmail}
                                        value={email}
                                        style={{ width: 250, height: 45, borderWidth: 0.5, borderColor: '#ccc', paddingLeft: 15, borderRadius: 3, fontSize: 16, fontFamily: 'RobotoLight' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                    <div style={{ color: '#fff', fontSize: 18, width: 250, height: 45, backgroundColor: '#000', marginLeft: 20, marginTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', fontFamily: 'FuturaLight', fontWeight: 'bold' }} onClick={submit}>
                        {loading == true ? (
                            <CircularProgress />
                        ) : (
                            <>Reset my password</>
                        )}
                    </div>
                </div>
            
            </div>
        </div>
  )
}

export default ResetPasswordScreen