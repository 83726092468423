import React, { useState, useEffect } from 'react'
import Navbar from '../Components/Navbar'
import Google from '../Assets/Images/search.png'
import { auth, db } from '../Firebase'
import { GoogleAuthProvider, getAuth, signInWithRedirect, getRedirectResult } from "firebase/auth";
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { token, pixelId } from '../Api';
import axios from 'axios';

function SignupScreen() {

    const navigation = useNavigate()
    const provider = new GoogleAuthProvider();
    const authenticate = getAuth()

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    //Error message
    const [firstNameError, setFirstNameError] = useState('#ccc')
    const [lastNameError, setLastNameError] = useState('#ccc')
    const [emailError, setEmailError] = useState('#ccc')
    const [passwordError, setPasswordError] = useState('#ccc')

    //loading
    const [loading, setLoading] = useState(false)

    const handleFirstName = (e) => {
        setFirstName(e.target.value)
    }

    const handleLastName = (e) => {
        setLastName(e.target.value)
    }

    const handleEmail = (e) => {
        setEmail(e.target.value)
    }

    const handlePassword = (e) => {
        setPassword(e.target.value)
    }

    const createAccount = () => {
        if (firstName == '') {
            setFirstNameError('red')
        } else {
            setFirstNameError('#ccc')
        }

        if (lastName == '') {
            setLastNameError('red')
        } else {
            setLastNameError('#ccc')
        }

        if (email == '') {
            setEmailError('red')
        } else {
            setEmailError('#ccc')
        }

        if (password == '') {
            setPasswordError('red')
        } else {
            setPasswordError('#ccc')
        }

        if (firstName !== '' && lastName !== '' && email !== '' && password !== '') {
            setLoading(true)
            auth.createUserWithEmailAndPassword(email, password).then(result => {
                addUser()
                console.log('result', result)
            }).catch(e => {
                console.log('error', e)
            })
        }
    }

    const addUser = () => {
        db.collection('Users')
            .doc(auth.currentUser?.uid)
            .set({
                FirstName: firstName,
                LastName: lastName,
                Email: email,
                Address: '',
                Country: '',
                State: '',
                PhoneNumber: '',
                Picture: ''
            }).then(() => {
                setLoading(false)
                facebookRegisterEvent()
                navigation('/')
            }).catch(e => {
                console.log(e)
            })
    }

    const facebookRegisterEvent = () => {
        axios.post(`https://graph.facebook.com/v20.0/${pixelId}/events?access_token=${token}`, {
            "data": [
                {
                    "event_name": "Registration",
                    "event_time": 1719515925,
                    "action_source": "website",
                    "event_source_url": "https://kridonvalley/Signup",
                    "user_data": {
                        "em": [
                            "7b17fb0bd173f625b58636fb796407c22b3d16fc78302d79f0fd30c2fc2fc068"
                        ],
                        "ph": [
                            null
                        ]
                    },
                    "custom_data": {
                        "currency": "NGN",
                        "value": ""
                    }
                }
            ]

        })
            .then(function (response) {
                console.log('Facebook event', response);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const googleSignin = () => {
        signInWithRedirect(authenticate, provider)
    }

    window.onload = () => {
        getReult();
    };

    const getReult = () => {
        getRedirectResult(authenticate)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access Google APIs.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;

                // The signed-in user info.
                const user = result.user;
                console.log('result', user)
                db.collection('Users')
                    .doc(user.uid)
                    .set({
                        FirstName: user.displayName,
                        LastName: '',
                        Email: user.email,
                        Address: '',
                        Country: '',
                        State: '',
                        PhoneNumber: user.phoneNumber,
                        Picture: user.photoURL
                    }).then(() => {
                        setLoading(false)
                        navigation('/')
                    }).catch(e => {
                        console.log(e)
                    })
                // IdP data available using getAdditionalUserInfo(result)
                // ...
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });
    }

    return (
        <div style={{ width: '100%' }}>
            <Navbar />
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ fontSize: 40, fontFamily: 'BrownSugar', color: '#000', marginTop: 50 }}>Welcome</div>
                <div style={{ marginTop: 30 }}>
                    <div style={{ fontSize: 18, fontFamily: 'FuturaLight', fontWeight: 'bold', color: '#000', marginLeft: 10 }}>First Name</div>
                    <input
                        placeholder='First name'
                        style={{ width: 300, height: 40, border: `1px solid ${firstNameError}`, paddingLeft: 10, marginTop: 10, borderRadius: 15 }}
                        onChange={handleFirstName}
                        value={firstName}
                    />
                </div>
                <div style={{ marginTop: 20 }}>
                    <div style={{ fontSize: 18, fontFamily: 'FuturaLight', fontWeight: 'bold', color: '#000', marginLeft: 10 }}>Last Name</div>
                    <input
                        placeholder='Last name'
                        style={{ width: 300, height: 40, border: `1px solid ${lastNameError}`, paddingLeft: 10, marginTop: 10, borderRadius: 15 }}
                        onChange={handleLastName}
                        value={lastName}
                    />
                </div>
                <div style={{ marginTop: 20 }}>
                    <div style={{ fontSize: 18, fontFamily: 'FuturaLight', fontWeight: 'bold', color: '#000', marginLeft: 10 }}>Email Address</div>
                    <input
                        placeholder='Email'
                        style={{ width: 300, height: 40, border: `1px solid ${emailError}`, paddingLeft: 10, marginTop: 10, borderRadius: 15 }}
                        onChange={handleEmail}
                        value={email}
                    />
                </div>
                <div style={{ marginTop: 20 }}>
                    <div style={{ fontSize: 18, fontFamily: 'FuturaLight', fontWeight: 'bold', color: '#000', marginLeft: 10 }}>Password</div>
                    <input
                        placeholder='Password'
                        style={{ width: 300, height: 40, border: `1px solid ${passwordError}`, paddingLeft: 10, marginTop: 10, borderRadius: 15 }}
                        onChange={handlePassword}
                        value={password}
                    />
                </div>
                <div style={{ width: 300, display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                    <div></div>
                    <div style={{ fontSize: 14, fontFamily: 'FuturaLight', fontWeight: 'bold', color: '#000', cursor: 'pointer' }}>Forgot password?</div>
                </div>
                {loading == false ? (
                    <div style={{ width: 300, height: 40, backgroundColor: '#000', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 15, marginTop: 20, fontSize: 16, fontFamily: 'FuturaLight', fontWeight: 'bold', color: '#fff', cursor: 'pointer' }} onClick={() => createAccount()}>
                        Create an Account
                    </div>
                ) : (
                    <div style={{ width: 300, height: 40, backgroundColor: '#000', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 15, marginTop: 20, fontSize: 16, fontFamily: 'FuturaLight', fontWeight: 'bold', color: '#fff', cursor: 'pointer' }}>
                        <CircularProgress style={{ fontSize: 20, color: '#fff' }} />
                    </div>
                )}
                <div style={{ width: 300, display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 40 }}>
                    <div style={{ width: 100, height: 1, backgroundColor: '#ccc' }}></div>
                    <div>Or</div>
                    <div style={{ width: 100, height: 1, backgroundColor: '#ccc' }}></div>
                </div>
                <div style={{ width: 300, height: 40, backgroundColor: '#fff', boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.2)', marginTop: 40, borderRadius: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => googleSignin()}>
                    <img src={Google} style={{ width: 20, height: 20, objectFit: 'contain' }} />
                    <div style={{ fontSize: 18, fontFamily: 'FuturaLight', color: '#000', fontWeight: 'bold', marginLeft: 15, cursor: 'pointer' }}>Continue with Google</div>
                </div>
                <div style={{ fontSize: 16, fontFamily: 'FuturaLight', color: '#000', fontWeight: 'bold', marginLeft: 15, cursor: 'pointer', marginTop: 10 }} onClick={() => navigation('/Login')}>Already have an account Login here</div>
            </div>
        </div>
    )
}

export default SignupScreen