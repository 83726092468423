import React, { useRef, useState, useEffect } from 'react';
import { fabric } from 'fabric';
import html2canvas from 'html2canvas';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import UndoIcon from '@mui/icons-material/Undo';
import SaveIcon from '@mui/icons-material/Save';
import ImageIcon from '@mui/icons-material/Image';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import RectangleIcon from '@mui/icons-material/Rectangle';
import Crop54Icon from '@mui/icons-material/Crop54';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import ChangeHistoryOutlinedIcon from '@mui/icons-material/ChangeHistoryOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import { DeleteForeverOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { templates } from '../Components/Template';
import Navbar from '../Components/Navbar';

function CustomScreen() {

  const canvasRef = useRef(null);
    const containerRef = useRef(null);
    const navigation = useNavigate()

    const [canvas, setCanvas] = useState(null);
    const [altCanvas, setAltCanvas] = useState(null)
    const [fontType, setFontType] = useState('Arial');
    const [defaultText, setDefaultText] = useState('Default Text');
    const [fontSize, setFontSize] = useState(24);
    const [isEditingText, setIsEditingText] = useState(false);
    const [currentText, setCurrentText] = useState('');
    const [inputPosition, setInputPosition] = useState({ x: 0, y: 0 });
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [showShape, setShowShape] = useState(false)
    const [fillColor, setFillColor] = useState('#000000'); // Default fill color (black)
    const [strokeColor, setStrokeColor] = useState('#000000'); // Default stroke color (black)
    const [showContextMenu, setShowContextMenu] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ top: 0, left: 0 });
    const [selectedColor, setSelectedColor] = useState('#000000');
    const [canvasSize, setCanvasSize] = useState({ width: 760, height: 560 });
    const [canvaLoad, setCanvaLoad] = useState(false)
    const reloadTriggered = useRef(false);
    const [showDesign, setShowDesign] = useState(false)
    const [canvasReady, setCanvasReady] = useState(false); // State to track if canvas is ready
    const [reloadTemplate, setReloadTemplate] = useState(false); // State to trigger reload
    const [progress, setProgress] = useState(0)

    const contextMenu = document.getElementsByClassName('context-menu');
    const location = useLocation()
    const id = location?.state?.templateId;
    const ids = location?.state?.ids;
    console.log('id', id)

    // Define available font types
    const fontTypes = ['Arial', 'Courier New', 'Georgia', 'Times New Roman', 'Verdana'];
    const fontSizes = [12, 16, 20, 24, 28, 32, 36, 40, 48, 56, 64];


    useEffect(() => {
        // Initialize fabric.js canvas
        const fabricCanvas = new fabric.Canvas(canvasRef.current, {
            width: canvasSize.width,
            height: canvasSize.height,
            backgroundColor: '#ccc',
        });


        // Set per-pixel target finding to true
        fabricCanvas.perPixelTargetFind = true;
        fabricCanvas.skipTargetFind = false;

        fabricCanvas.preserveObjectStacking = true;

        fabricCanvas.setWidth(canvasSize.width);
        fabricCanvas.setHeight(canvasSize.height);

        // Add a right-click event listener
        const handleContextMenu = (e) => {
            e.preventDefault();
            e.stopPropagation(); // Stop event propagation
            console.log('Right-click event triggered'); // Debugging line
            // Get the active object (the object that was clicked on)
            const activeObject = fabricCanvas.getActiveObject();
            if (activeObject) {
                // Get the position of the active object in canvas coordinates
                const activeObjectPosition = {
                    left: activeObject.left + activeObject.width * activeObject.scaleX,
                    top: activeObject.top,
                };

                // Convert canvas coordinates to window coordinates
                // const canvasRect = fabricCanvas.lowerCanvasEl.getBoundingClientRect();
                const position = {
                    top: activeObjectPosition.top,
                    left: activeObjectPosition.left
                };

                // Set the context menu position next to the active object
                setShowContextMenu(true);
                setContextMenuPosition(position);

            }
        };
        setCanvas(fabricCanvas);
        setCanvasReady(true);

        fabricCanvas.on('contextmenu', handleContextMenu);
        document.addEventListener('contextmenu', handleContextMenu);

        console.log('checking')
        // Clean up the event listener on unmount
        return () => {
            fabricCanvas.off('contextmenu', handleContextMenu);
            fabricCanvas.off('object:dblclick');
            fabricCanvas.off('object:selected');
            fabricCanvas.dispose();
            // Set canvas as ready after initializing
            setCanvasReady(0);
        };

        // Clean up on unmount
    }, [canvasRef.current, canvasSize]);


    useEffect(() => {
        const loadSelectedTemplate = async () => {
            if (canvasReady && canvas) {
                const templateId = Number(id);
                const selectedTemplate = templates.find(template => template.id === templateId);
                console.log('Selected Template:', selectedTemplate);

                if (selectedTemplate !== undefined) {
                    await loadTemplate(selectedTemplate);
                } else {
                    console.warn(`No template found with ID: ${templateId}`);
                }
            }
        };

        loadSelectedTemplate();
    }, [canvasReady, canvas, id]);

    const loadTemplate = async (template) => {
        if (!canvas) {
            console.error('Canvas is not ready');
            return;
        }

        canvas.clear();

        for (const element of template.elements) {
            if (element.type === 'svg') {
                await new Promise((resolve) => {
                    fabric.loadSVGFromURL(element.url, (objects, options) => {
                        const svgGroup = fabric.util.groupSVGElements(objects, options);

                        const desiredWidth = 700; // Adjust this width as desired
                        const desiredHeight = 400; // Adjust this height as desired

                        const scaleX = desiredWidth / svgGroup.width;
                        const scaleY = desiredHeight / svgGroup.height;

                        svgGroup.set({
                            ...element.options,
                            scaleX,
                            scaleY,
                        });

                        canvas.add(svgGroup);

                        if (element.options.zIndex !== undefined) {
                            canvas.moveTo(svgGroup, element.options.zIndex);
                        }

                        canvas.renderAll();
                        resolve();
                    });
                });
            } else if (element.type === 'textbox') {
                const textElement = new fabric.Textbox(element.text, {
                    ...element.options,
                    width: element.options.width, // Apply the width property if specified
                    fontWeight: element.options.fontWeight,
                    lineHeight: element.options.lineHeight,
                });
                canvas.add(textElement);

                if (element.options.zIndex !== undefined) {
                    canvas.moveTo(textElement, element.options.zIndex);
                }
                canvas.renderAll();
            } else if (element.type === 'rect') {
                const rectElement = new fabric.Rect(element.options);
                canvas.add(rectElement);

                if (element.options.zIndex !== undefined) {
                    canvas.moveTo(rectElement, element.options.zIndex);
                }
                canvas.renderAll();
            } else if (element.type === 'image') {
                await new Promise((resolve) => {
                    const fileExtension = element.url.split('.').pop().toLowerCase();

                    if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
                        fabric.Image.fromURL(element.url, (img) => {
                            const desiredWidth = element.options.width || img.width;
                            const desiredHeight = element.options.height || img.height;

                            const aspectRatio = img.width / img.height;
                            let scaleFactor;
                            if (desiredWidth / desiredHeight > aspectRatio) {
                                scaleFactor = desiredHeight / img.height;
                            } else {
                                scaleFactor = desiredWidth / img.width;
                            }

                            img.scale(scaleFactor);

                            img.set({
                                left: element.options.left || 0,
                                top: element.options.top || 0,
                                selectable: true,
                                zIndex: element.options.zIndex || 0,
                            });

                            canvas.add(img);

                            if (element.options.zIndex !== undefined) {
                                canvas.moveTo(img, element.options.zIndex);
                            }

                            canvas.renderAll();
                            resolve();
                        });
                    } else {
                        console.error(`Unsupported file type: ${fileExtension}`);
                        resolve();
                    }
                });
            }

            canvas.renderAll();
        }

        canvas.renderAll();
    };

    // Attach a global click event listener
    useEffect(() => {
        const handleClick = (e) => {
            if (!e.target.closest('.context-menu')) {
                // Hide the context menu if the click is outside the menu
                setShowContextMenu(false);
            }
        };

        document.addEventListener('click', handleClick);

        // Clean up the event listener on unmount
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);



    // Function to add default text to the canvas
    const addDefaultText = () => {
        if (canvas) {
            // Create a new text object with the default text
            const textElement = new fabric.Textbox(defaultText, {
                left: 100,
                top: 100,
                fontSize: 24,
                fontFamily: fontType,
                textAlign: 'left', // Set horizontal alignment (left, center, right)
                splitByGrapheme: true, // Enable better text wrapping
                width: 300
            });

            // Add the text object to the canvas
            canvas.add(textElement);

            // Set the text object as the active object for immediate editing
            canvas.setActiveObject(textElement);
            console.log('text element', textElement instanceof fabric.Text);
            //textElement.enterEditing();

            canvas.renderAll();
        }
    };

    // Handle font type change
    const handleFontTypeChange = (event) => {
        const selectedFontType = event.target.value;
        setFontType(selectedFontType);

        // If there's an active object (selected text), apply the new font type
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            if (activeObject && activeObject.type === 'textbox') {
                activeObject.set('fontFamily', selectedFontType);
                canvas.renderAll();
            }
        }
    };

    // Function to toggle bold formatting
    const toggleBold = () => {
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            if (activeObject && activeObject.type === 'textbox') {
                const newFontWeight = activeObject.fontWeight === 'bold' ? 'normal' : 'bold';
                activeObject.set('fontWeight', newFontWeight);
                setIsBold(newFontWeight === 'bold');
                canvas.renderAll();
            }
        }
    };

    // Function to toggle italic formatting
    const toggleItalic = () => {
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            if (activeObject && activeObject.type === 'textbox') {
                const newFontStyle = activeObject.fontStyle === 'italic' ? 'normal' : 'italic';
                activeObject.set('fontStyle', newFontStyle);
                setIsItalic(newFontStyle === 'italic');
                canvas.renderAll();
            }
        }
    };

    // Update the font size of the active text object whenever the user changes the input
    useEffect(() => {
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            if (activeObject && activeObject.type === 'textbox') {
                activeObject.set('fontSize', fontSize);
                canvas.renderAll();
            }
        }
    }, [fontSize, canvas]);

    // Handle input change
    const handleInputChange = (e) => {
        const newText = e.target.value;
        setCurrentText(newText);

        // Update the active text object's text in the canvas
        const activeObject = canvas.getActiveObject();
        if (activeObject && activeObject.type === 'textbox') {
            activeObject.set('text', newText);
            canvas.renderAll();
        }
    };

    // Handle blur event to hide the input field
    const handleInputBlur = () => {
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            if (activeObject && activeObject.type === 'textbox') {
                // Update the text property of the active object with the current input text
                activeObject.set('textbox', currentText);

                // Re-render the canvas to reflect the changes
                canvas.renderAll();

                // Hide the input field and stop editing mode
                setIsEditingText(false);
                setCurrentText('');
            }
        }
    };

    const handleInputKeyPress = (e) => {
        if (e.key === 'Enter') {
            // When Enter key is pressed, hide the input field and save the changes
            handleInputBlur();
        }
    };

    // Function to toggle underline formatting
    const toggleUnderline = () => {
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            if (activeObject && activeObject.type === 'textbox') {
                // Toggle the underline property of the active text object
                const newUnderline = !activeObject.underline;
                activeObject.set('underline', newUnderline);

                // Update the canvas
                canvas.renderAll();
            }
        }
    };

    const changeTextColor = (event) => {
        const newColor = event.target.value;

        if (canvas) {
            const activeObject = canvas.getActiveObject();
            if (activeObject && activeObject.type === 'textbox') {
                // Set the new color of the active text object
                activeObject.set('fill', newColor);

                // Update the canvas
                canvas.renderAll();
            }
        }
    };

    const alignTextToCenter = () => {
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            if (activeObject && activeObject.type === 'textbox') {
                // Update the text alignment to center
                activeObject.set('textAlign', 'center');
                // Re-render the canvas
                canvas.renderAll();
            }
        }
    };

    const alignTextToLeft = () => {
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            if (activeObject && activeObject.type === 'textbox') {
                // Update the text alignment to center
                activeObject.set('textAlign', 'left');
                // Re-render the canvas
                canvas.renderAll();
            }
        }
    };

    const alignTextToRight = () => {
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            if (activeObject && activeObject.type === 'textbox') {
                // Update the text alignment to center
                activeObject.set('textAlign', 'right');
                // Re-render the canvas
                canvas.renderAll();
            }
        }
    };

    // Function to handle image file selection
    const handleImageUpload = (event) => {
        const file = event.target.files[0]; // Get the selected file
        if (file) {
            const reader = new FileReader(); // Create a FileReader to read the file

            // Load the image file as a data URL
            reader.onload = (e) => {
                const imageDataUrl = e.target.result; // The image data URL

                // Create a new fabric.Image object from the image data URL
                fabric.Image.fromURL(imageDataUrl, (img) => {
                    // Set the initial position and other properties of the image
                    img.set({
                        left: 100, // Set the x-coordinate (you can adjust as needed)
                        top: 100, // Set the y-coordinate (you can adjust as needed)
                        scaleX: 0.5, // Adjust the scale of the image (you can change as needed)
                        scaleY: 0.5, // Adjust the scale of the image (you can change as needed)
                    });

                    // Add the image object to the canvas
                    canvas.add(img);
                    // Render the canvas to display the image
                    canvas.renderAll();
                });
            };

            // Read the file as a data URL
            reader.readAsDataURL(file);
        }
    };

    // Function to add a rectangle to the canvas
    const addRectangle = () => {
        const rect = new fabric.Rect({
            left: 100,
            top: 100,
            width: 100,
            height: 50,
            fill: fillColor, // Set the fill color
            stroke: strokeColor, // Set the stroke color
            strokeWidth: 2, // Set the stroke width
        });
        canvas.add(rect);
        canvas.insertAt(rect, 0);
        canvas.renderAll();
    };

    // Function to add a circle to the canvas
    const addCircle = () => {
        const circle = new fabric.Circle({
            left: 100,
            top: 100,
            radius: 50,
            fill: fillColor, // Set the fill color
            stroke: strokeColor, // Set the stroke color
            strokeWidth: 2, // Set the stroke width
        });
        canvas.add(circle);
        canvas.renderAll();
    };

    // Function to add a triangle to the canvas
    const addTriangle = () => {
        const triangle = new fabric.Triangle({
            left: 100,
            top: 100,
            width: 100,
            height: 100,
            fill: fillColor, // Set the fill color
            stroke: strokeColor, // Set the stroke color
            strokeWidth: 2, // Set the stroke width
        });
        canvas.add(triangle);
        canvas.renderAll();
    };

    // Function to handle changes to the fill color
    const handleFillColorChange = (event) => {
        const color = event.target.value;
        setFillColor(color);

        // Update the fill color of the selected shape if there is one
        const activeObject = canvas.getActiveObject();
        if (activeObject && activeObject.type !== 'activeSelection') {
            activeObject.set('fill', color);
            canvas.renderAll();
        }
    };

    // Function to handle changes to the stroke color
    const handleStrokeColorChange = (event) => {
        const color = event.target.value;
        setStrokeColor(color);

        // Update the stroke color of the selected shape if there is one
        const activeObject = canvas.getActiveObject();
        if (activeObject && activeObject.type !== 'activeSelection') {
            activeObject.set('stroke', color);
            canvas.renderAll();
        }
    };

    // Function to adjust the z-index of objects
    const adjustZIndex = (object, zIndex) => {
        if (canvas) {
            // Get all objects on the canvas
            const objects = canvas.getObjects();

            // Sort objects based on zIndex
            objects.sort((a, b) => a.zIndex - b.zIndex);

            // Insert the object at the specified zIndex position
            objects.splice(zIndex, 0, object);

            // Clear the canvas
            canvas.clear();

            // Add objects back to the canvas in the sorted order
            objects.forEach((obj) => {
                canvas.add(obj);
            });

            // Render the canvas
            canvas.renderAll();
        }
    };

    // Function to move the active object up in the stack order
    const moveUp = () => {
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            if (activeObject) {
                // Move the active object forward in the stack order
                activeObject.bringForward();
                setShowContextMenu(!showContextMenu)

                // Remove the highlight from the active object
                canvas.discardActiveObject();
                canvas.renderAll();
            }
        }
    };

    // Function to move the active object down in the stack order
    const moveDown = () => {
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            if (activeObject) {
                activeObject.sendBackwards();
                setShowContextMenu(!showContextMenu)

                // Remove the highlight from the active object
                canvas.discardActiveObject();
                canvas.renderAll();
            }
        }
    };

    // Function to bring the active object to the front
    const bringToFront = () => {
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            if (activeObject) {
                canvas.bringToFront(activeObject);
                setShowContextMenu(!showContextMenu)

                // Remove the highlight from the active object
                canvas.discardActiveObject();
                canvas.renderAll();
            }
        }
    };

    // Function to send the active object to the back
    const sendToBack = () => {
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            if (activeObject) {
                canvas.sendToBack(activeObject);
                setShowContextMenu(!showContextMenu)

                // Remove the highlight from the active object
                canvas.discardActiveObject();
                canvas.renderAll();
            }
        }
    };

    // Function to change the color of the selected SVG object
    const changeSVGColor = (color) => {
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            if (activeObject && activeObject.type === 'group') {
                // Iterate through the objects in the group and change the fill color
                activeObject.getObjects().forEach((obj) => {
                    obj.set('fill', color);
                });

                // Render the canvas to display the changes
                canvas.renderAll();
            }
        }
    };

    // Handle color change
    const handleColorChange = (event) => {
        const newColor = event.target.value;
        setSelectedColor(newColor);
        changeSVGColor(newColor);
    };

    const convertCanvasToImage = () => {
        if (!canvas) {
            console.error('Canvas is not available');
            return;
        }

        const dataURL = canvas.toDataURL({
            format: 'png', // You can also use 'jpeg' or 'bmp'
            quality: 1.0, // Quality parameter for JPEG format (0.1 to 1.0)
            multiplier: 5
        });

        // You can use this data URL to display the image or download it
        console.log('Canvas Data URL:', dataURL);

        if (dataURL !== '') {

            // const storageRef = storage.ref();
            // const uploadTask = storageRef.child(`images/${dataURL}`).put(dataURL);

            // uploadTask.on(
            //     'state_changed',
            //     (snapshot) => {
            //         const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            //         setProgress(progress);
            //     },
            //     (error) => {
            //         console.error(error);
            //     },
            //     () => {
            //         uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {

            //             // Store image metadata in Firestore
            //             db.collection('Users').doc(auth.currentUser?.uid).collection('Templates').add({
            //                 url: downloadURL,
            //                 name: location.state?.name,
            //                 image: location.state?.image,
            //                 description: location.state?.description,
            //                 material: location.state?.material,
            //                 finishing: location.state?.finishing,
            //                 paperThickness: location.state?.paperThickness,
            //                 lamination: location.state?.lamination,
            //                 corner: location.state?.corner,
            //                 mugType: location.state?.mugType,
            //                 accessories: location.state?.accessories,
            //                 materialType: location.state?.materialType,
            //                 size: location.state?.size,
            //                 quantity: location.state?.quantity,
            //                 shipping: location.state?.shipping,
            //                 price: location.state?.price,
            //                 mainPrice: location.state?.mainPrice,
            //                 totalPrice: location.state?.totalPrice,
            //                 templateId: location.state?.templateId,
            //             }).then(() => {
            //                 console.log('Success upload')

            //             });
            //         });
            //     }
            // );

            navigation('/Preview', {
                state: {
                    url: dataURL,
                    name: location.state?.name,
                    image: location.state?.image,
                    description: location.state?.description,
                    material: location.state?.material,
                    finishing: location.state?.finishing,
                    paperThickness: location.state?.paperThickness,
                    lamination: location.state?.lamination,
                    corner: location.state?.corner,
                    mugType: location.state?.mugType,
                    accessories: location.state?.accessories,
                    materialType: location.state?.materialType,
                    size: location.state?.size,
                    quantity: location.state?.quantity,
                    shipping: location.state?.shipping,
                    price: location.state?.price,
                    mainPrice: location.state?.mainPrice,
                    totalPrice: location.state?.totalPrice,
                    templateId: location.state?.templateId,
                }
            })
        }
        // Example: Create an image element to display the canvas content as an image
        // const imgElement = document.createElement('img');
        // imgElement.src = dataURL;
        // document.body.appendChild(imgElement);

        // // Example: Trigger a download of the image
        // const link = document.createElement('a');
        // link.href = dataURL;
        // link.download = 'canvas-image.png';
        // link.click();
    };

  return (
    <div style={{ width: '100%' }}>
      <Navbar />
    <div style={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <div style={{ width: 1200, height: 50, border: '1px solid #ccc', marginTop: 50, display: 'flex', alignItems: 'center', borderStyle: 'dashed' }}>
            <div style={{ height: 40, width: 70, border: '1px solid #ccc', borderBottomWidth: 0, borderTopWidth: 0, borderLeftWidth: 0, display: 'flex', alignItems: 'center' }}>
                <div style={{ marginLeft: 10, cursor: 'pointer' }} onClick={addDefaultText}>
                    <TextFormatIcon style={{ fontSize: 30, color: '#292929' }} />
                </div>
            </div>

            <select value={fontType} onChange={handleFontTypeChange} style={{ height: 40, width: 170, border: '1px solid #ccc', borderBottomWidth: 0, borderTopWidth: 0, borderLeftWidth: 0, display: 'flex', alignItems: 'center', paddingLeft: 10 }}>
                {fontTypes.map(font => (
                    <option key={font} value={font}>
                        {font}
                    </option>
                ))}
            </select>

            <select
                value={fontSize}
                onChange={(e) => setFontSize(Number(e.target.value))}
                style={{ height: 40, width: 70, border: '1px solid #ccc', borderBottomWidth: 0, borderTopWidth: 0, borderLeftWidth: 0, display: 'flex', alignItems: 'center', paddingLeft: 10 }}
            >
                {fontSizes.map((size) => (
                    <option key={size} value={size}>
                        {size}
                    </option>
                ))}
            </select>

            <div style={{ height: 40, width: 180, border: '1px solid #ccc', borderBottomWidth: 0, borderTopWidth: 0, borderLeftWidth: 0, display: 'flex', alignItems: 'center', paddingLeft: 10 }}>
                <div onClick={toggleBold} style={{ cursor: 'pointer' }}><FormatBoldIcon style={{ fontSize: 25, color: '#000' }} /></div>
                <div onClick={toggleItalic} style={{ cursor: 'pointer', marginLeft: 10 }}><FormatItalicIcon style={{ fontSize: 25, color: '#000' }} /></div>
                <div onClick={toggleUnderline} style={{ cursor: 'pointer', marginLeft: 10 }}><FormatUnderlinedIcon style={{ fontSize: 25, color: '#000' }} /></div>
                <div onClick={toggleUnderline} style={{ cursor: 'pointer', marginLeft: 10 }}><input type="color" onChange={changeTextColor} style={{ cursor: 'pointer' }} /></div>
            </div>
            <div style={{ height: 40, width: 130, border: '1px solid #ccc', borderBottomWidth: 0, borderTopWidth: 0, borderLeftWidth: 0, display: 'flex', alignItems: 'center', paddingLeft: 10 }}>
                <div onClick={alignTextToLeft} style={{ cursor: 'pointer' }}><FormatAlignLeftIcon style={{ fontSize: 25, color: '#000' }} /></div>
                <div onClick={alignTextToCenter} style={{ cursor: 'pointer', marginLeft: 15 }}><FormatAlignCenterIcon style={{ fontSize: 25, color: '#000' }} /></div>
                <div onClick={alignTextToRight} style={{ cursor: 'pointer', marginLeft: 15 }}><FormatAlignRightIcon style={{ fontSize: 25, color: '#000' }} /></div>
            </div>
        </div>
        <div style={{ display: 'flex' }}>
            <div style={{ width: 50, height: 600, border: '1px solid #ccc', marginLeft: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', borderTopWidth: 0, borderBottomWidth: 0, borderStyle: 'dashed' }}>
                <div>
                    <input type="file" accept="image/*" onChange={handleImageUpload} id='imagefile' style={{ display: 'none' }} />
                </div>
                <label htmlFor='imagefile' style={{ marginTop: 10 }}>
                    <ImageIcon style={{ fontSize: 25, color: '#000' }} />
                </label>
                <div style={{ marginTop: 10, position: 'relative' }}>
                    <div style={{ cursor: 'pointer' }} onClick={() => setShowShape(!showShape)}>
                        <FormatShapesIcon style={{ fontSize: 25, color: '#000', }} />
                    </div>
                    {showShape == false ? (
                        <></>
                    ) : (
                        <div style={{ width: 150, height: 150, backgroundColor: '#fff', position: 'absolute', top: 0, left: 25, boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 100 }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20, cursor: 'pointer' }} onClick={addRectangle}>
                                <div><Crop54Icon style={{ fontSize: 30, color: '#000' }} /></div>
                                <div style={{ fontSize: 14, color: '#000', marginLeft: 10, width: 100 }}>Rectangle</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20, marginTop: 10, cursor: 'pointer' }} onClick={addCircle}>
                                <div><CircleOutlinedIcon style={{ fontSize: 30, color: '#000' }} /></div>
                                <div style={{ fontSize: 14, color: '#000', marginLeft: 10, width: 100 }}>Circle</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20, marginTop: 10, cursor: 'pointer' }} onClick={addTriangle}>
                                <div><ChangeHistoryOutlinedIcon style={{ fontSize: 30, color: '#000' }} /></div>
                                <div style={{ fontSize: 14, color: '#000', marginLeft: 10, width: 100 }}>Triangle</div>
                            </div>
                        </div>
                    )}
                </div>
                <div style={{ marginTop: 10, alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                    <div>Fill</div>
                    <input type="color" value={fillColor} onChange={handleFillColorChange} style={{ width: 30, height: 30 }} />
                    <div style={{ fontSize: 12, marginTop: 10 }}>Stroke</div>
                    <input type="color" value={strokeColor} onChange={handleStrokeColorChange} style={{ width: 30, height: 10 }} />
                    <div style={{ fontSize: 12, marginTop: 10 }}>Svg</div>
                    <input type="color" value={selectedColor} onChange={handleColorChange} style={{ width: 30, height: 30 }} />
                </div>
            </div>
            <div ref={containerRef} style={{ position: 'relative', width: 800, height: 600, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {/* Single canvas tag */}
                <canvas ref={canvasRef} />
                {/* Pop-out div for context menu */}
                {showContextMenu && (
                    <div
                        className="context-menu"
                        style={{
                            position: 'absolute',
                            top: contextMenuPosition.top,
                            left: contextMenuPosition.left,
                            width: 250,
                            height: 'auto',
                            backgroundColor: '#fff',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            padding: '10px',
                            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2)',
                        }}
                    >
                        {/* Add menu content here */}
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20, marginTop: 20, cursor: 'pointer' }} onClick={bringToFront}>
                            <div><ArrowUpwardOutlinedIcon style={{ fontSize: 25, color: '#000' }} /></div>
                            <div style={{ fontSize: 16, color: '#000', marginLeft: 10, width: 200 }}>Send To Front</div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20, marginTop: 10, cursor: 'pointer' }} onClick={moveUp}>
                            <div><ArrowUpwardOutlinedIcon style={{ fontSize: 25, color: '#000' }} /></div>
                            <div style={{ fontSize: 16, color: '#000', marginLeft: 10, width: 200 }}>Move Upward</div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20, marginTop: 20, cursor: 'pointer' }} onClick={moveDown}>
                            <div><ArrowDownwardOutlinedIcon style={{ fontSize: 25, color: '#000' }} /></div>
                            <div style={{ fontSize: 16, color: '#000', marginLeft: 10, width: 200 }}>Move Downward</div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20, marginTop: 20, cursor: 'pointer' }} onClick={sendToBack}>
                            <div><ArrowDownwardOutlinedIcon style={{ fontSize: 25, color: '#000' }} /></div>
                            <div style={{ fontSize: 16, color: '#000', marginLeft: 10, width: 200 }}>Send To Back</div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20, marginTop: 20, cursor: 'pointer' }}>
                            <div><DeleteForeverOutlined style={{ fontSize: 25, color: 'red' }} /></div>
                            <div style={{ fontSize: 16, color: '#000', marginLeft: 10, width: 200 }}>Delete</div>
                        </div>
                        {/* Add buttons, inputs, etc. for manipulating the object */}
                    </div>
                )}
            </div>

            {/* Template selector */}
            <div style={{ width: 350, height: 600, border: '1px solid #ccc', marginRight: 15, borderTopWidth: 0, borderBottomWidth: 0, borderStyle: 'dashed', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {/* <label>Choose a template:</label>
                <select onChange={(e) => {
                    const templateId = Number(e.target.value);
                    const selectedTemplate = templates.find(template => template.id === templateId);
                    if (selectedTemplate) {
                        loadTemplate(selectedTemplate);
                    }
                }}>
                    <option value="">-- Select a template --</option>
                    {templates.map(template => (
                        <option key={template.id} value={template.id}>
                            {template.name}
                        </option>
                    ))}
                </select>
                <div>
                    <label htmlFor="canvas-size">Select Canvas Size:</label>
                    <select id="canvas-size" onChange={handleSizeChange} defaultValue="800x600">
                        <option value="800x600">800 x 600</option>
                        <option value="1024x768">1024 x 768</option>
                        <option value="1280x720">1280 x 720</option>
                        <option value="1600x900">1600 x 900</option>
                    </select>
                </div> */}
                <div style={{ width: 250, height: 500, border: '1px solid #ccc', marginRight: 15, borderStyle: 'dashed', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ width: 150, height: 400, border: '1px solid #ccc', marginRight: 15, borderStyle: 'dashed', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ width: 50, height: 300, border: '1px solid #ccc', marginRight: 15, borderStyle: 'dashed', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div style={{ width: 1200, height: 50, border: '1px solid #ccc', display: 'flex', alignItems: 'center', borderStyle: 'dashed', justifyContent: 'space-between' }}>
            <div></div>
            <div style={{ display: 'flex' }}>
                <div style={{ width: 200, height: 35, border: '1px solid #ccc', marginRight: 10, backgroundColor: '#000', color: '#fff', fontSize: 20, fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={convertCanvasToImage}>
                    DONE
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default CustomScreen