import React, { useState, useEffect } from 'react'
import Navbar from '../Components/Navbar'
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { db, auth } from '../Firebase';
import CartComponent from '../Components/CartComponent';
import { usePaystackPayment } from 'react-paystack';
import { useNavigate } from 'react-router-dom';

const config = {
    reference: (new Date()).getTime().toString(),
    email: "user@example.com",
    amount: 20000, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: 'pk_live_eb4e6b88b9229786b75a00ce130765b6d03a218a',
};

// you can call this function anything
const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);
};

// you can call this function anything
const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log('closed')
}

const PaystackHookExample = () => {
    const initializePayment = usePaystackPayment(config);
    return (
        <div>
            <button onClick={() => {
                initializePayment(onSuccess, onClose)
            }}>Paystack Hooks Implementation</button>
        </div>
    );
};


function CartScreen() {

    const navigation = useNavigate()

    const [cart, setCart] = useState([])
    const [price, setPrice] = useState([])
    const [total, setTotal] = useState(0)

    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);


        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])



    const [text1, setText1] = useState(30)
    const [imgWidth, setImgWidth] = useState(100)
    const [cartError, setCartError] = useState(false)

    useEffect(() => {
        if (screenSize.width < 1500) {
            setText1(30)
        }

        if (screenSize.width < 1200) {
            setText1(20)
        }

        if (screenSize.width < 600) {
            setText1(15)
        }

        if (screenSize.width > 1500) {
            setText1(30)
        }
    }, [screenSize]) //text1

    useEffect(() => {
        if (screenSize.width < 1500) {
            setImgWidth(100)
        }

        if (screenSize.width < 1200) {
            setImgWidth(80)
        }

        if (screenSize.width < 600) {
            setImgWidth(50)
        }

        if (screenSize.width > 1500) {
            setImgWidth(100)
        }
    }, [screenSize]) //text1

    useEffect(() => {
        db.collection('Users')
            .doc(auth.currentUser?.uid)
            .collection('Cart')
            .onSnapshot((snapshot) => {
                const carts = snapshot.docs.map(doc => {
                    const data = doc.data()
                    const id = doc.id
                    return { data, id }
                })
                setCart(carts)
                console.log('Cart', carts)
            })
    }, [auth.currentUser?.uid])

    useEffect(() => {
        const thePrice = cart.map(item => (
            item.data.Price
        ))
        setPrice(thePrice)
    }, [cart])

    const calculateSum = (arr) => {
        return arr.reduce((acc, num) => acc + num, 0);
    };

    useEffect(() => {
        const sum = calculateSum(price);
        console.log('Sum', sum)
        setTotal(sum)
    }, [price])

    const remove = (id) => {
        db.collection('Users')
            .doc(auth.currentUser?.uid)
            .collection('Cart')
            .doc(id)
            .delete()
    }

    const checkout = () => {
        if (cart == '') {
            setCartError(true)
        } else {
            setCartError(false)
            navigation('/Checkout', { state: { cart: cart } })
        }
    }

    return (
        <div style={{ width: '100%' }}>
            <Navbar />
            <div style={{ width: '100%', display: 'flex', flexDirection: screenSize.width < 1200 ? 'column' : 'row' }}>
                <div style={{ width: screenSize.width < 1200 ? '97%' : '60%', paddingRight: 20, backgroundColor: '#fff', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2)', marginLeft: 20, marginTop: 30 }}>
                    <div style={{ width: '100%', height: 105, display: 'flex', alignItems: 'center', border: '1px solid #ccc', borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0 }}>
                        <input type='checkbox' style={{ marginLeft: 20 }} />
                        <div style={{ marginLeft: 10, fontSize: 18, fontFamily: 'FuturaLight', fontWeight: 'bold' }}>All</div>
                    </div>
                    {cart.map((item, index) => (
                        <div style={{ width: '100%', height: 105, display: 'flex', alignItems: 'center', border: '1px solid #ccc', borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, justifyContent: 'space-between' }} key={index}>
                            <div style={{ display: 'flex' }}>
                                <input type='checkbox' style={{ marginLeft: 20 }} />
                                <div style={{ width: imgWidth, height: imgWidth, backgroundColor: '#eee', marginLeft: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <img src={item.data?.Image} style={{ width: '70%', height: '70%', objectFit: 'contain' }} />
                                </div>
                            </div>

                            <div style={{ width: 'auto', marginLeft: screenSize.width < 1200 ? 0 : 20 }}>
                                <div style={{ fontSize: 20, fontFamily: 'FuturaLight', fontWeight: 'bold' }}>{item.data?.Name}</div>
                                <div style={{ fontSize: 16, fontFamily: 'FuturaLight', marginTop: 5 }}>Qty: {item.data?.Quantity}</div>
                            </div>
                            <div style={{ fontSize: text1, fontFamily: 'FuturaLight', fontWeight: 'bold', }}>
                                ₦{(item.data?.Price).toLocaleString()}
                            </div>
                            <div style={{ marginLeft: 0, cursor: 'pointer' }} onClick={() => remove(item.id)}>
                                <CloseOutlinedIcon style={{ fontSize: 20, color: '#000' }} />
                            </div>
                        </div>
                    ))}
                </div>
                <div style={{ width: 300, height: 500, backgroundColor: '#fff', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2)', marginTop: 30, marginLeft: 30, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ fontSize: 30, fontFamily: 'FuturaLight', fontWeight: 'bold', marginTop: 15 }}>Cart</div>
                    <div style={{ width: '90%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 20 }}>
                        <div style={{ fontSize: 20, fontFamily: 'FuturaLight', color: '#000', fontWeight: 'bold' }}>No of Items</div>
                        <div style={{ fontSize: 18, fontFamily: 'FuturaLight', color: '#000', fontWeight: 'bold' }}>{cart.length}</div>
                    </div>
                    <div style={{ width: '90%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 10 }}>
                        <div style={{ fontSize: 20, fontFamily: 'FuturaLight', color: '#000', fontWeight: 'bold' }}>VAT</div>
                        <div style={{ fontSize: 18, fontFamily: 'FuturaLight', color: '#000', fontWeight: 'bold' }}>₦0</div>
                    </div>
                    <div style={{ width: '90%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 10 }}>
                        <div style={{ fontSize: 20, fontFamily: 'FuturaLight', color: '#000', fontWeight: 'bold' }}>SUBTOTAL</div>
                        <div style={{ fontSize: 18, fontFamily: 'FuturaLight', color: '#000', fontWeight: 'bold' }}>₦{total.toLocaleString()}</div>
                    </div>
                    <div style={{ width: '90%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 30 }}>
                        <input style={{ width: 150, height: 45, border: '1px solid #ccc' }} />
                        <div style={{ fontSize: 18, fontFamily: 'FuturaLight', color: 'green', fontWeight: 'bold' }}>Coupon</div>
                    </div>
                    <div style={{ width: '90%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 20 }}>
                        <div style={{ fontSize: 20, fontFamily: 'FuturaLight', color: '#000', fontWeight: 'bold' }}>TOTAL</div>
                        <div style={{ fontSize: 30, fontFamily: 'FuturaLight', color: '#000', fontWeight: 'bold' }}>₦{total.toLocaleString()}</div>
                    </div>
                    {cartError == false ? (
                        <></>
                    ) : (
                        <div style={{ fontSize: 18, color: 'red', fontFamily: 'FuturaLight', fontWeight: 'bold' }}>Cart is empty</div>
                    )}
                    <div style={{ width: '90%', height: 45, backgroundColor: '#000', marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20, fontWeight: 'bold', fontFamily: 'FuturaLight', color: '#fff', cursor: 'pointer' }} onClick={() => checkout()}>CHECKOUT</div>
                </div>
            </div>
        </div>
    )
}

export default CartScreen