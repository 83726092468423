import React, { useEffect, useState } from 'react'
import Logo from '../Assets/Images/logo.png'
import SearchIcon from '@mui/icons-material/Search';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../Firebase';
import Accordion from 'react-bootstrap/Accordion';
import MenuIcon from '@mui/icons-material/Menu';

function Navbar() {

  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const navigation = useNavigate()

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);


    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize])

  const [user, setUser] = useState(false)
  const [isHovered, setIsHovered] = useState(false);
  const [test, setTest] = useState('')
  const [test1, setTest1] = useState(null)
  const [allProduct, setAllProduct] = useState([])
  const [theProduct, setTheProduct] = useState([])
  const [theProduct1, setTheProduct1] = useState([])
  const [display, setDisplay] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const catHover = (name) => {
    setTest(name)
  }

  const proHover = (name) => {
    setTest1(name)
  }

  const productCategory = [
    {
      id: 1,
      name: 'T-shirt'
    },
    {
      id: 2,
      name: 'Trouser'
    },
    {
      id: 3,
      name: 'Shoes'
    },
    // {
    //   id: 4,
    //   name: 'Business Cards'
    // },
    // {
    //   id: 5,
    //   name: 'Calendars'
    // },
    // {
    //   id: 6,
    //   name: 'Campaign Materials'
    // },
    // {
    //   id: 7,
    //   name: 'Caps & Hats'
    // },
    // {
    //   id: 8,
    //   name: 'ClothesTag'
    // },
    // {
    //   id: 9,
    //   name: 'Clothing & Apparel'
    // },
    // {
    //   id: 10,
    //   name: 'Corporate Gifts'
    // },
    // {
    //   id: 11,
    //   name: 'Envelopes'
    // },
    // {
    //   id: 12,
    //   name: 'Flyers & Handbills'
    // },
    // {
    //   id: 13,
    //   name: 'Frames & Wall Arts'
    // },
    // {
    //   id: 14,
    //   name: 'Greeting Cards'
    // },
    // {
    //   id: 15,
    //   name: 'ID Cards'
    // },
    // {
    //   id: 16,
    //   name: 'Labels'
    // },
    // {
    //   id: 17,
    //   name: 'Letterhead'
    // },
    // {
    //   id: 18,
    //   name: 'Mugs'
    // },
    // {
    //   id: 19,
    //   name: 'Notepads and Jotters'
    // },
    // {
    //   id: 20,
    //   name: 'Posters'
    // },
    // {
    //   id: 21,
    //   name: 'Presentation Folders'
    // },
    // {
    //   id: 22,
    //   name: 'Promotional Items'
    // },
    // {
    //   id: 23,
    //   name: 'Stickers'
    // },
    // {
    //   id: 24,
    //   name: 'Umbrella'
    // },
    // {
    //   id: 25,
    //   name: 'Wedding Stationery'
    // }
  ]

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(true)
      } else {
        setUser(false)
      }
    })

    db.collection('Products')
      .get()
      .then((snapshot) => {
        const pro = snapshot.docs.map(doc => {
          const data = doc.data()
          const id = doc.id
          return { data, id }
        })
        setAllProduct(pro)
      }).catch(e => {
        console.log(e)
      })
  }, [])

  useEffect(() => {
    const productText = allProduct.filter(item => item.data.Category == test)
    setTheProduct(productText)
  }, [test])

  useEffect(() => {
    const product = allProduct.filter(item => item.id == test1)
    setTheProduct1(product)
  }, [test1, test])

  const product = (id, name) => {
    navigation('/Product', { state: { id: id } })
  }

  const onClick = () => {
    setDisplay(!display)
  }

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: 0, alignItems: 'center', height: 70, backgroundColor: '#fff', }}>
      <div style={{ flex: 1, backgroundColor: '#fff', display: display == false ? 'none' : 'block', marginTop: -30 }} className='col-12'>
        <Accordion defaultActiveKey="0" style={{ position: 'absolute', width: '100%', overflowY: 'auto' }}>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#fff' }}>
            <div></div>
            <div style={{ fontSize: 25, marginRight: 20, cursor: 'pointer' }} onClick={() => onClick()}>X</div>
          </div>
          {productCategory.map((item, index) => (
            <Accordion.Item eventKey={item.id} style={{ overflowY: 'auto' }}>
              <Accordion.Header onClick={() => catHover(item.name)}>{item.name}</Accordion.Header>
              {theProduct.map((item, index) => (
                <Accordion.Body onClick={() => product(item.id, item.data.Name)} style={{ cursor: 'pointer' }}>
                  {item.data.Name}
                </Accordion.Body>
              ))}
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
      {screenSize.width < 800 ? (
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: screenSize.width < 800 ? 10 : 20 }} onClick={() => onClick()}>
          <MenuIcon style={{ fontSize: 40, color: '#000' }} />
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: screenSize.width < 800 ? 10 : 20 }}>
          <div style={{ color: '#3F3F3F', fontFamily: 'FuturaLight', fontWeight: 'bold', fontSize: screenSize.width < 600 ? 12 : 18, cursor: 'pointer' }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => navigation('/Shop')}
          >
            STORE
          </div>
          <div style={{ color: '#3F3F3F', fontFamily: 'FuturaLight', fontWeight: 'bold', fontSize: screenSize.width < 600 ? 12 : 18, marginLeft: 20, cursor: 'pointer' }} onClick={() => navigation('/')}>HOME</div>
          {/* <div style={{ color: '#3F3F3F', fontFamily: 'FuturaLight', fontWeight: 'bold', fontSize: screenSize.width < 600 ? 12 : 18, marginLeft: 20 }}>OUR STORY</div> */}
        </div>
      )}

      <div style={{ cursor: 'pointer' }} onClick={() => navigation('/')}>
        {/* <div style={{ color: '#3F3F3F', fontFamily: 'BrownSugar', fontSize: screenSize.width < 600 ? 18 : 30 }}>KRIDON VALLEY</div> */}
        <img src={Logo} style={{width: 40, height: 40, objectFit: 'contain'}} />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginRight: screenSize.width < 600 ? 20 : 50 }}>
        {user == true ? (
          <div style={{ color: '#3F3F3F', fontFamily: 'FuturaLight', fontWeight: 'bold', fontSize: screenSize.width < 600 ? 15 : 16, cursor: 'pointer' }} onClick={() => navigation('/Account')}>
            PROFILE
          </div>
        ) : (
          <div style={{ color: '#3F3F3F', fontFamily: 'FuturaLight', fontWeight: 'bold', fontSize: screenSize.width < 600 ? 15 : 16, cursor: 'pointer' }} onClick={() => navigation('/Login')}>
            LOGIN
          </div>
        )}

        {/* <div style={{ marginLeft: screenSize.width < 600 ? 20 : 40, fontWeight: 'bold', fontSize: screenSize.width < 600 ? 12 : 16 }}>
          <SearchIcon style={{ fontSize: 25, color: '#3F3F3F' }} />
        </div> */}
        {user == true ? (
          <div style={{ marginLeft: screenSize.width < 600 ? 20 : 40, fontWeight: 'bold', fontSize: screenSize.width < 600 ? 12 : 16, cursor: 'pointer' }} onClick={() => navigation('/Cart')}>
            <LocalMallOutlinedIcon style={{ fontSize: 25, color: '#3F3F3F' }} />
          </div>
        ) : (
          <></>
        )}

      </div>

      <div style={{ width: 1200, height: 500, backgroundColor: '#fff', position: 'fixed', marginLeft: 10, top: 44, zIndex: 100, visibility: isHovered == false ? 'hidden' : 'visible', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', borderRadius: 5, overflow: 'hidden', display: 'flex' }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div style={{ overflowY: 'auto', width: '25%', borderRightWidth: 1, border: '1px solid #ccc', borderLeftWidth: 0, borderTopWidth: 0, borderBottomWidth: 0, marginTop: 20, marginBottom: 20 }}>
          {productCategory.map((item, index) => (
            <>
              <p key={index} style={{ color: '#506682', padding: 10, backgroundColor: 'transparent', width: 'auto', paddingLeft: 10, marginBottom: -5, cursor: 'pointer' }}
                onMouseEnter={(e) => (e.target.style.backgroundColor = '#c2e6ea', e.target.style.color = '#fff', (catHover(item.name)))}
                onMouseLeave={(e) => (e.target.style.backgroundColor = 'transparent', e.target.style.color = '#506682', (proHover()))}
              >{item.name}</p>
            </>
          ))}
        </div>
        <div style={{ overflowY: 'auto', width: '50%', borderRightWidth: 1, border: '1px solid #ccc', borderLeftWidth: 0, borderTopWidth: 0, borderBottomWidth: 0, marginTop: 20, marginBottom: 20 }}>
          {theProduct.map((item, index) => (
            <p key={index} style={{ color: '#506682', padding: 10, backgroundColor: 'transparent', width: 'auto', paddingLeft: 10, marginBottom: -5, cursor: 'pointer' }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = '#c2e6ea', e.target.style.color = '#fff', (proHover(item.id)))}
              onMouseLeave={(e) => (e.target.style.backgroundColor = 'transparent', e.target.style.color = '#506682')}
            >{item.data.Name}</p>
          ))}
        </div>
        <div style={{ overflowY: 'auto', width: '25%', borderRightWidth: 0, border: '1px solid #ccc', borderLeftWidth: 0, borderTopWidth: 0, borderBottomWidth: 0, marginTop: 20, marginBottom: 20 }}>
          <>
            {theProduct1.map((item, index) => (
              <>
                <div style={{ width: '90%', marginLeft: 15 }}>
                  <img src={item.data.Image} style={{ width: '100%', height: 'auto', objectFit: 'contain' }} />
                </div>
                <div style={{ marginLeft: 15, marginTop: 20 }}>
                  <p style={{ fontSize: 13, fontFamily: 'RobotoBold', color: '#506682' }}>STARTING AT</p>
                  <p style={{ color: '#000', fontSize: 25, fontFamily: 'RobotoBold', display: 'flex', alignItems: 'center', marginTop: -15 }}>₦{item.data.Price}<p style={{ fontSize: 17, marginLeft: 10, color: '#506682' }}>per 1</p></p>
                </div>
                <div style={{ width: 100, height: 45, backgroundColor: '#03B3C1', marginLeft: 15, borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: -10, cursor: 'pointer', fontSize: 20, color: '#fff' }} onClick={() => product(item.id, item.data.Name)}>
                  Browse
                </div>
              </>
            ))}
          </>
        </div>
      </div>
    </div>
  )
}

export default Navbar