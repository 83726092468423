import React, { useState, useEffect, useRef } from 'react'
import { db, auth, storage } from '../Firebase';
import EditIcon from '@mui/icons-material/Edit';
import Person from '../Assets/Images/person4.png'
import { CircularProgress } from '@mui/material'
import Navbar from '../Components/Navbar';

function EditProfileScreen() {

    const fileInputRef = useRef(null);

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [number, setNumber] = useState('')
    const [picture, setPicture] = useState('')
    const [img, setImg] = useState('')
    const [url, setUrl] = useState('')

    const [loading, setLoading] = useState(false)

    const [editedFirstName, setEditedFirstName] = useState('')
    const [editedLastName, setEditedLastName] = useState('')
    const [editedPhone, setEditedPhone] = useState('')


    useEffect(() => {
        const timer = setTimeout(() => {
            // Your delayed code here
            db.collection('Users')
                .doc(auth.currentUser?.uid)
                .onSnapshot((snapshot) => {
                    const { FirstName, LastName, PhoneNumber, Image } = snapshot.data()
                    setFirstName(FirstName)
                    setLastName(LastName)
                    setNumber(PhoneNumber)
                    setPicture(Image)
                })
        }, 3000); // 3000 milliseconds = 3 seconds

        // Cleanup the timer if the component unmounts before the delay completes
        return () => clearTimeout(timer);

    }, [])

    useEffect(() => {
        setEditedFirstName(firstName)
        setEditedLastName(lastName)
        setEditedPhone(number)
    }, [firstName])

    const handleFirstName = (e) => {
        setEditedFirstName(e.target.value)
    }

    const handleLastName = (e) => {
        setEditedLastName(e.target.value)
    }

    const handlePhone = (e) => {
        setEditedPhone(e.target.value)
    }

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        // Do something with the selected file, for example, upload it to a server
        setImg(file);
        console.log('Selected file:', file);
    };

    const handleIconClick = () => {
        // Trigger the file input click event when the icon is clicked
        fileInputRef.current.click();
    };

    useEffect(() => {
        if (img) {
            const storageRef = storage.ref(`images/${img.name}`);
            storageRef.put(img).then(() => {
                storageRef.getDownloadURL().then((url) => {
                    // Store the download URL in Firestore
                    setUrl(url)
                });
            });
        }
    }, [img])

    const update = () => {
        setLoading(true)
        db.collection('Users')
            .doc(auth.currentUser?.uid)
            .update({
                FirstName: firstName,
                LastName: lastName,
                PhoneNumber: editedPhone,
                Image: url
            }).then(() => {
                setLoading(false)
                console.log('A success')
            }).catch(e => {
                console.log(e)
            })
    }

    return (
        <div style={{ width: '100%' }}>
            <Navbar />
            <div style={{ marginLeft: 20 }}>
                <p style={{ fontSize: 25, fontFamily: 'FuturaLight', color: '#384A62', fontWeight: 'bold' }}>Edit Profile</p>
            </div>
            <div style={{ width: 150, height: 150, backgroundColor: '#ccc', marginLeft: 20, borderRadius: 150, overflow: 'hidden' }}>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleImageUpload}
                />
                <div style={{ width: 40, height: 40, backgroundColor: '#eee', position: 'absolute', marginTop: 110, marginLeft: 100, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50, opacity: 0.7, cursor: 'pointer' }} onClick={handleIconClick}>
                    <EditIcon />
                </div>
                {picture == '' ? (
                    <>
                        {url == '' ? (
                            <img src={Person} style={{ width: '100%', objectFit: 'contain' }} />
                        ) : (
                            <img src={url} style={{ width: '100%', objectFit: 'contain' }} />
                        )}
                    </>

                ) : (
                    <img src={picture} style={{ width: '100%', objectFit: 'contain' }} />
                )}
            </div>
            <div style={{ marginTop: 20 }}>
                <div style={{ marginLeft: 20, display: 'flex', flexDirection: 'column' }}>
                    <label style={{ marginBottom: 20, fontFamily: 'FuturaLight', fontWeight: 'bold' }}>First Name:</label>
                    <input placeholder={firstName} onChange={handleFirstName} style={{ width: 300, height: 50, borderWidth: 0.5, borderColor: '#ccc', paddingLeft: 15, borderRadius: 5 }} />
                </div>
                <div style={{ marginLeft: 20, display: 'flex', flexDirection: 'column', marginTop: 20 }}>
                    <label style={{ marginBottom: 20, fontFamily: 'FuturaLight', fontWeight: 'bold' }}>Last Name:</label>
                    <input placeholder={lastName} onChange={handleLastName} style={{ width: 300, height: 50, borderWidth: 0.5, borderColor: '#ccc', paddingLeft: 15, borderRadius: 5 }} />
                </div>
                <div style={{ marginLeft: 20, display: 'flex', flexDirection: 'column', marginTop: 20 }}>
                    <label style={{ marginBottom: 20, fontFamily: 'FuturaLight', fontWeight: 'bold' }}>Number:</label>
                    <input placeholder={number} onChange={handlePhone} style={{ width: 300, height: 50, borderWidth: 0.5, borderColor: '#ccc', paddingLeft: 15, borderRadius: 5 }} />
                </div>
            </div>
            <div style={{ color: '#fff', fontSize: 18, fontFamily: 'FuturaLight', width: 300, height: 50, backgroundColor: '#000', marginLeft: 20, marginTop: 40, borderRadius: 3, marginBottom: 100, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', fontWeight: 'bold' }} onClick={update}>
                {loading == false ? (
                    <>Update</>
                ) : (
                    <CircularProgress />
                )}
            </div>
        </div>
    )
}

export default EditProfileScreen