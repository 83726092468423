import React, { useState, useEffect } from 'react'
import Mission from '../Assets/Images/mission.png'

function AboutUs() {

    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);


        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    return (
        <div style={{ width: '100%' }}>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: '90%', height: screenSize.width < 800 ? 400 : 800, backgroundColor: '#eee', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', position: 'relative', boxShadow: '1 1px 1px 1 rgba(0, 0, 0, 0.3)', borderRadius: 20 }}>
                    <div style={{ width: screenSize.width < 800 ? '90%' : '60%', textAlign: 'center', fontSize: screenSize.width < 800 ? 12 : 20, fontFamily: 'FuturaLight', color: '#000', marginTop: 20 }}>Welcome to Kridon Valley, your number one source for all things fashion. We're dedicated to providing you the very best of T-shirts, trousers, and a wide variety of other clothing, with an emphasis on quality, style, and affordability.

                        Founded in 2024, Kridon Valley has come a long way from its beginnings. When we first started out, our passion for fashion drove us to start our own business. Our commitment to delivering the finest and trendiest clothing to our customers is what fuels our growth and success.

                        We are proud to source our materials responsibly and ensure that our products are made under ethical working conditions. If you have any questions or comments, please don't hesitate to contact us.</div>
                    <div style={{ fontSize: screenSize.width < 800 ? 60 : 150, fontWeight: 'bold', color: '#d3d3d3' }}>ABOUT US</div>

                </div>
            </div>
        </div>
    )
}

export default AboutUs