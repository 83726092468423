import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: "AIzaSyANlsBK73ThHEsgglj_2jFW1ev-ZpsEbM4",
    authDomain: "kridonvalley.firebaseapp.com",
    projectId: "kridonvalley",
    storageBucket: "kridonvalley.appspot.com",
    messagingSenderId: "829057228433",
    appId: "1:829057228433:web:b005ce633ad453a4cce930"
  };

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const auth = firebase.auth()
export const storage = firebase.storage()
export const currentTime = firebase.firestore.Timestamp