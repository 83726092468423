import React, { useState, useEffect } from 'react'
import Navbar from '../Components/Navbar'
import { useNavigate, useLocation } from 'react-router-dom'
import { db, auth, currentTime } from '../Firebase'
import { usePaystackPayment, PaystackButton } from 'react-paystack';
import { CircularProgress } from '@mui/material';
import axios from 'axios';
import { token, pixelId } from '../Api';

function CheckoutScreen() {

    const route = useLocation()
    const navigation = useNavigate()
    const cart = route.state?.cart;
    console.log('Routes', cart)

    const [price, setPrice] = useState([])
    const [total, setTotal] = useState(0)

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [address, setAddress] = useState('')
    const [state, setState] = useState('')

    const [firstNameError, setFirstNameError] = useState('#ccc')
    const [lastNameError, setLastNameError] = useState('#ccc')
    const [Error, setEmailError] = useState('#ccc')
    const [phoneNumberError, setPhoneNumberError] = useState('#ccc')
    const [addressError, setAddressError] = useState('#ccc')
    const [stateError, setStateError] = useState('#ccc')

    const [loading, setLoading] = useState(false)

    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);


        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    useEffect(() => {
        const thePrice = cart.map(item => (
            item.data.Price
        ))
        setPrice(thePrice)
    }, [cart])

    const calculateSum = (arr) => {
        return arr.reduce((acc, num) => acc + num, 0);
    };

    useEffect(() => {
        const sum = calculateSum(price);
        console.log('Sum', sum)
        setTotal(sum)
    }, [price])

    useEffect(() => {

        const timer = setTimeout(() => {
            // Your delayed code here
            db.collection('Users')
                .doc(auth.currentUser?.uid)
                .get()
                .then((snapshot) => {
                    const { FirstName, LastName, PhoneNumber, Address, State, Email } = snapshot.data()
                    setFirstName(FirstName)
                    setLastName(LastName)
                    setPhoneNumber(PhoneNumber)
                    setAddress(Address)
                    setState(State)
                    setEmail(Email)
                }).catch(e => {
                    console.log('err', e)
                })
        }, 3000); // 3000 milliseconds = 3 seconds

        // Cleanup the timer if the component unmounts before the delay completes
        return () => clearTimeout(timer);

    }, [])

    const config = {
        reference: (new Date()).getTime().toString(),
        email: email,
        amount: total * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
        publicKey: 'pk_live_eb4e6b88b9229786b75a00ce130765b6d03a218a',
    };

    // you can call this function anything
    const onSuccess = (reference) => {
        // Implementation for whatever you want to do with reference and after success call.
        //console.log(reference);
        order()
        axios.post(`https://graph.facebook.com/v20.0/${pixelId}/events?access_token=${token}`, {
            "data": [
                {
                    "event_name": "Purchase",
                    "event_time": 1719515925,
                    "action_source": "website",
                    "user_data": {
                        "em": [
                            "7b17fb0bd173f625b58636fb796407c22b3d16fc78302d79f0fd30c2fc2fc068"
                        ],
                        "ph": [
                            null
                        ]
                    },
                    "custom_data": {
                        "currency": "NGN",
                        "value": total
                    }
                }
            ]

        })
            .then(function (response) {
                console.log('Facebook event', response);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    // you can call this function anything
    const onClose = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
        console.log('closed')
    }

    const initializePayment = usePaystackPayment(config);

    const handleFirstName = (e) => {
        setFirstName(e.target.value)
    }

    const handleLastName = (e) => {
        setLastName(e.target.value)
    }

    const handleEmail = (e) => {
        setEmail(e.target.value)
    }

    const handleState = (e) => {
        setState(e.target.value)
    }

    const handlePhoneNumber = (e) => {
        setPhoneNumber(e.target.value)
    }

    const handleAddress = (e) => {
        setAddress(e.target.value)
    }

    const payment = () => {
        if (firstName == '') {
            setFirstNameError('red')
        } else {
            setFirstNameError('#ccc')
        }

        if (lastName == '') {
            setLastNameError('red')
        } else {
            setLastNameError('#ccc')
        }

        if (state == '') {
            setStateError('red')
        } else {
            setStateError('#ccc')
        }

        if (phoneNumber == '') {
            setPhoneNumberError('red')
        } else {
            setPhoneNumberError('#ccc')
        }

        if (address == '') {
            setAddressError('red')
        } else {
            setAddressError('#ccc')
        }

        if (firstName !== '' && lastName !== '' && state !== '' && phoneNumber !== '', address !== '') {
            setLoading(true)
            db.collection('Users')
                .doc(auth.currentUser?.uid)
                .update({
                    FirstName: firstName,
                    LastName: lastName,
                    Address: address,
                    PhoneNumber: phoneNumber,
                    State: state
                }).then(() => {

                    initializePayment(onSuccess, onClose);
                }).catch(e => {
                    console.log(e)
                })
        }
    }

    const order = () => {
        const promises = cart.map(item => {
            return db.collection('Orders')
                .add({
                    name: item.data?.Name,
                    category: item.data?.Category || '',
                    image: item.data?.Image || '',
                    size: item.data?.Size || '',
                    material: item.data?.Material || '',
                    price: item.data?.Price || '',
                    quantity: item.data?.Quantity || '',
                    productId: item.data?.ProductId,
                    status: 'Pending',
                    date: currentTime.now(),
                    userId: auth.currentUser?.uid
                });
        });

        Promise.all(promises)
            .then(() => {
                console.log('All orders booked successfully');
                setLoading(false)
                navigation('/Thankyou');
                clearCart()
            })
            .catch((e) => {
                console.log(e);
            });
    }

    const clearCart = () => {
        const promise = cart.map(item => {
            return db.collection('Users')
                .doc(auth.currentUser?.uid)
                .collection('Cart')
                .doc(item.id)
                .delete()
        })

        Promise.all(promise)
            .then(() => {
                console.log('All cart deleted successfully');
            })
            .catch((e) => {
                console.log(e);
            });
    }

    return (
        <div style={{ width: '100%', backgroundColor: '#fff' }}>
            <Navbar />
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around', flexDirection: screenSize.width < 1200 ? 'column' : 'row', marginLeft: 15 }}>
                <div style={{ width: screenSize.width < 1200 ? '100%' : '60%', height: 'auto', paddingBottom: 10 }}>
                    <div style={{ fontSize: 30, fontWeight: 'bold' }}>Secure Checkout</div>
                    <div style={{ width: '90%', height: 45, backgroundColor: '#F4FFFC', border: '1px solid #ccc', borderRadius: 5, marginTop: 20, color: '#000', fontSize: 18, fontFamily: 'FuturaLight', display: 'flex', alignItems: 'center', fontWeight: 'bold', paddingLeft: 20 }}>Checkout securely - It only takes a few minutes</div>
                    <div style={{ fontSize: 23, fontWeight: 'bold', marginTop: 30 }}>Contact details</div>

                    <div style={{ display: 'flex', marginTop: 20, flexDirection: screenSize.width < 700 ? 'column' : 'row' }}>
                        <div>
                            <div style={{ color: '#000', fontSize: 18, fontFamily: 'FuturaLight', fontWeight: 'bold' }}>First Name</div>
                            <input type='text' placeholder={'First Name'} onChange={handleFirstName} value={firstName} style={{ width: 300, height: 45, border: `1px solid ${firstNameError}`, borderRadius: 10, paddingLeft: 10, fontFamily: 'FuturaLight', fontWeight: 'bold' }} />
                        </div>
                        <div style={{ marginLeft: screenSize.width < 700 ? 0 : 50, marginTop: screenSize.width < 700 ? 10 : 0 }}>
                            <div style={{ color: '#000', fontSize: 18, fontFamily: 'FuturaLight', fontWeight: 'bold' }}>Last Name</div>
                            <input type='text' placeholder={'Last Name'} onChange={handleLastName} value={lastName} style={{ width: 300, height: 45, border: `1px solid ${lastNameError}`, borderRadius: 10, paddingLeft: 10, fontFamily: 'FuturaLight', fontWeight: 'bold' }} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: 20, flexDirection: screenSize.width < 700 ? 'column' : 'row' }}>
                        <div>
                            <div style={{ color: '#000', fontSize: 18, fontFamily: 'FuturaLight', fontWeight: 'bold' }}>State</div>
                            <input type='text' placeholder={'State'} onChange={handleState} value={state} style={{ width: 300, height: 45, border: `1px solid ${stateError}`, borderRadius: 10, paddingLeft: 10, fontFamily: 'FuturaLight', fontWeight: 'bold' }} />
                        </div>
                        <div style={{ marginLeft: screenSize.width < 700 ? 0 : 50, marginTop: screenSize.width < 700 ? 10 : 0 }}>
                            <div style={{ color: '#000', fontSize: 18, fontFamily: 'FuturaLight', fontWeight: 'bold' }}>Phone Number</div>
                            <input type='text' placeholder={'Phone Number'} onChange={handlePhoneNumber} value={phoneNumber} style={{ width: 300, height: 45, border: `1px solid ${phoneNumberError}`, borderRadius: 10, paddingLeft: 10, fontFamily: 'FuturaLight', fontWeight: 'bold' }} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: 20 }}>
                        <div>
                            <div style={{ color: '#000', fontSize: 18, fontFamily: 'FuturaLight', fontWeight: 'bold' }}>Address</div>
                            <textarea type='text' placeholder='Address' onChange={handleAddress} value={address} style={{ width: screenSize.width < 700 ? 300 : 650, height: 105, border: `1px solid ${addressError}`, borderRadius: 10, fontFamily: 'FuturaLight', fontWeight: 'bold' }} />
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ width: 350, height: 310, boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2)', overflow: 'hidden', paddingBottom: 10 }}>
                        <div style={{ fontSize: 20, color: '#000', fontWeight: 'bold', marginTop: 10, marginLeft: 5 }}>Review Order Details</div>
                        <div style={{ display: 'flex', overflowX: 'scroll' }}>
                            {cart.map((item, index) => (
                                <div>
                                    <div style={{ flexShrink: 0, width: 120, height: 120, backgroundColor: '#eee', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 5, borderRadius: 10 }}>
                                        <img src={item.data?.Image} style={{ width: '95%', height: '95%', objectFit: 'contain' }} />
                                    </div>
                                    <div style={{ fontSize: 14, fontFamily: 'FuturaLight', fontWeight: 'bold', color: '#000', marginLeft: 5 }}>{item.data?.Name}</div>
                                </div>
                            ))}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: 5, width: '70%', marginTop: 20 }}>
                            <div style={{ fontSize: 18, fontWeight: 'bold', color: '#7588A3', fontFamily: 'FuturaLight' }}>Items</div>
                            <div style={{ fontSize: 16, color: '#000', fontFamily: 'FuturaLight' }}>{cart.length}</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: 5, width: '70%', marginTop: 5 }}>
                            <div style={{ fontSize: 18, fontWeight: 'bold', color: '#7588A3', fontFamily: 'FuturaLight' }}>SubTotal</div>
                            <div style={{ fontSize: 16, fontWeight: 'bold', color: '#000', fontFamily: 'FuturaLight' }}>₦{total.toLocaleString()}</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: 5, width: '70%', marginTop: 5 }}>
                            <div style={{ fontSize: 18, fontWeight: 'bold', color: '#7588A3', fontFamily: 'FuturaLight' }}>GrandTotal</div>
                            <div style={{ fontSize: 20, fontWeight: 'bold', color: '#000', fontFamily: 'FuturaLight' }}>₦{total.toLocaleString()}</div>
                        </div>
                    </div>
                    <div style={{ width: 350, height: 45, backgroundColor: '#000', marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20, fontFamily: 'FuturaLight', fontWeight: 'bold', color: '#fff', cursor: 'pointer', marginBottom: 20 }} onClick={() => payment()}>
                        {loading == false ? (
                            <>Checkout</>
                        ) : (
                            <CircularProgress style={{ fontSize: 20, color: '#fff' }} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CheckoutScreen