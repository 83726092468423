import React, { useState, useEffect } from 'react'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Shirt from '../Assets/Images/tshirt.png'
import Shirt1 from '../Assets/Images/tshirt1.png'
import { useNavigate } from 'react-router-dom';
import { Product } from './data';
import { db } from '../Firebase';

function Intrested() {

    const navigation = useNavigate()

    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);


        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    const [products, setProducts] = useState([])

    useEffect(() => {
       db.collection('Products')
       .limit(10)
       .get()
       .then((snapshot) => {
            const pro = snapshot.docs.map(doc => {
                const data = doc.data()
                const id = doc.id
                return {data, id}
            })
            setProducts(pro)
       })
    }, [])

    return (
        <div style={{ width: '100%', height: 500, color: '#000', zIndex: 500, marginTop: 70 }}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', marginLeft: screenSize.width < 600 ? 20 : 100 }}>
                    <div style={{ width: screenSize.width < 600 ? 100 : 120, height: screenSize.width < 600 ? 40 : 50, backgroundColor: '#000', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#fff', cursor: 'pointer' }} onClick={() => navigation('/Shop')}>New Arrivals</div>
                    <div style={{ width: screenSize.width < 600 ? 100 : 120, height: screenSize.width < 600 ? 40 : 50, backgroundColor: '#fff', border: '1px solid #000', marginLeft: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#000', cursor: 'pointer' }} onClick={() => navigation('/Shop')}>Best Seller</div>
                </div>
                <div style={{ fontSize: 20, color: '#000', fontWeight: 'bold', fontFamily: 'FuturaLight', marginRight: screenSize.width < 600 ? 20 : 100, cursor: 'pointer' }} onClick={() => navigation('/Shop')}>
                    SEE ALL
                </div>
            </div>

            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 40 }}>
                <div style={{ width: '88%', height: screenSize.width < 600 ? 300 : 400, display: 'flex', gap: 10, overflowX: 'scroll' }}>
                    {products.map((item, index) => (
                        <div style={{ width: screenSize.width < 600 ? 200 : 300, height: screenSize.width < 600 ? 285 : 385, cursor: 'pointer' }} key={index} onClick={() => navigation('/Product', {state: {id: item.id}})}>
                            <div style={{ width: screenSize.width < 600 ? 200 : 300, height: screenSize.width < 600 ? 200 : 300, backgroundColor: '#EAEEEF', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ position: 'absolute', right: 5, top: 5 }}><FavoriteBorderIcon style={{ fontSize: 20, color: '#000' }} /></div>
                                <div style={{ fontSize: 18, color: '#000', fontFamily: 'FuturaLight', position: 'absolute', fontWeight: 'bold', bottom: 5, left: 5 }}>₦{(item.data?.Price).toLocaleString()}</div>
                                <img src={item.data?.Image} style={{ width: '97%', height: '97%', objectFit: 'cover' }} />
                            </div>
                            <div style={{ fontSize: 20, fontFamily: 'FuturaLight', color: '#000', fontWeight: 'bold', marginTop: 10 }}>{item.data?.Name}</div>
                            <div style={{ fontSize: 18, fontFamily: 'FuturaLight', color: '#000', marginTop: 10 }}>{item.data?.Category}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Intrested