import React, { useState, useEffect } from 'react'
import Navbar from '../Components/Navbar'
import Google from '../Assets/Images/search.png'
import { auth, db } from '../Firebase'
import { GoogleAuthProvider, getAuth, signInWithRedirect, getRedirectResult } from "firebase/auth";
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function LoginScreen() {

    const navigation = useNavigate()
    const provider = new GoogleAuthProvider();
    const authenticate = getAuth()

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    //Error message
    const [emailError, setEmailError] = useState('#ccc')
    const [passwordError, setPasswordError] = useState('#ccc')
    const [incorrectCredentials, setIncorrectCredentials] = useState(false)

    //loading
    const [loading, setLoading] = useState(false)

    const handleEmail = (e) => {
        setEmail(e.target.value)
    }

    const handlePassword = (e) => {
        setPassword(e.target.value)
    }

    const loginAccount = () => {

        if (email == '') {
            setEmailError('red')
        } else {
            setEmailError('#ccc')
        }

        if (password == '') {
            setPasswordError('red')
        } else {
            setPasswordError('#ccc')
        }

        if (email !== '' && password !== '') {
            setLoading(true)
            auth.signInWithEmailAndPassword(email, password).then(result => {
                navigation('/')
                setIncorrectCredentials(false)
                console.log('result', result)
            }).catch(e => {
                console.log('error', e)
                setIncorrectCredentials(true)
            })
        }
    }

    const googleSignin = () => {
        signInWithRedirect(authenticate, provider)
    }

    window.onload = () => {
        getReult();
    };

    const getReult = () => {
        getRedirectResult(authenticate)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access Google APIs.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;

                // The signed-in user info.
                const user = result.user;
                console.log('result', user)
                db.collection('Users')
                    .doc(user.uid)
                    .set({
                        FirstName: user.displayName,
                        LastName: '',
                        Email: user.email,
                        Address: '',
                        Country: '',
                        State: '',
                        PhoneNumber: user.phoneNumber,
                        Picture: user.photoURL
                    }).then(() => {
                        setLoading(false)
                        navigation('/')
                    }).catch(e => {
                        console.log(e)
                    })
                // IdP data available using getAdditionalUserInfo(result)
                // ...
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });
    }

    return (
        <div style={{ width: '100%' }}>
            <Navbar />
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ fontSize: 40, fontFamily: 'BrownSugar', color: '#000', marginTop: 50 }}>Welcome</div>

                <div style={{ marginTop: 20 }}>
                    <div style={{ fontSize: 18, fontFamily: 'FuturaLight', fontWeight: 'bold', color: '#000', marginLeft: 10 }}>Email Address</div>
                    <input
                        placeholder='Email'
                        style={{ width: 300, height: 40, border: `1px solid ${emailError}`, paddingLeft: 10, marginTop: 10, borderRadius: 15 }}
                        onChange={handleEmail}
                        value={email}
                    />
                </div>
                <div style={{ marginTop: 20 }}>
                    <div style={{ fontSize: 18, fontFamily: 'FuturaLight', fontWeight: 'bold', color: '#000', marginLeft: 10 }}>Password</div>
                    <input
                        placeholder='Password'
                        style={{ width: 300, height: 40, border: `1px solid ${passwordError}`, paddingLeft: 10, marginTop: 10, borderRadius: 15 }}
                        onChange={handlePassword}
                        value={password}
                    />
                </div>
                <div style={{ width: 300, display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                    <div></div>
                    <div style={{ fontSize: 14, fontFamily: 'FuturaLight', fontWeight: 'bold', color: '#000', cursor: 'pointer' }} onClick={() => navigation('/ResetPassword')}>Forgot password?</div>
                </div>
                {loading == false ? (
                    <div style={{ width: 300, height: 40, backgroundColor: '#000', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 15, marginTop: 20, fontSize: 16, fontFamily: 'FuturaLight', fontWeight: 'bold', color: '#fff', cursor: 'pointer' }} onClick={() => loginAccount()}>
                        Login
                    </div>
                ) : (
                    <div style={{ width: 300, height: 40, backgroundColor: '#000', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 15, marginTop: 20, fontSize: 16, fontFamily: 'FuturaLight', fontWeight: 'bold', color: '#fff', cursor: 'pointer' }}>
                        <CircularProgress style={{ fontSize: 20, color: '#fff' }} />
                    </div>
                )}
                {incorrectCredentials == false ? (
                    <></>
                ) : (
                    <div style={{ width: 300, display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                        <div style={{ fontSize: 14, fontFamily: 'FuturaLight', fontWeight: 'bold', color: 'red', cursor: 'pointer' }}>Incorrect Email or password</div>
                    </div>
                )}

                <div style={{ width: 300, display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 40 }}>
                    <div style={{ width: 100, height: 1, backgroundColor: '#ccc' }}></div>
                    <div>Or</div>
                    <div style={{ width: 100, height: 1, backgroundColor: '#ccc' }}></div>
                </div>
                <div style={{ width: 300, height: 40, backgroundColor: '#fff', boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.2)', marginTop: 40, borderRadius: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => googleSignin()}>
                    <img src={Google} style={{ width: 20, height: 20, objectFit: 'contain' }} />
                    <div style={{ fontSize: 18, fontFamily: 'FuturaLight', color: '#000', fontWeight: 'bold', marginLeft: 15, cursor: 'pointer' }}>Continue with Google</div>
                </div>
                <div style={{ fontSize: 16, fontFamily: 'FuturaLight', color: '#000', fontWeight: 'bold', marginLeft: 15, cursor: 'pointer', marginTop: 10 }} onClick={() => navigation('/Signup')}>Don't have an account Signup here</div>
            </div>
        </div>
    )
}

export default LoginScreen