
import brochure2 from '../Assets/Images/template/brochure/reportfrontcover.jpg'
import brochureLogo from '../Assets/Images/template/brochure/black.jpeg'
import Shirt from '../Assets/Images/template/editshirt.png'

export const templates = [
    {
        id: 3,
        name: 'Template 1',
        elements: [
            {
                type: 'image',
                url: Shirt,
                options: { left: 20, top: 20, scale: 0.5, zIndex: 100, width: 750, height: 550 },
            },
            // {
            //     type: 'textbox',
            //     text: 'Ventures Platforms Foundation',
            //     options: { left: 420, top: 100, fill: '#000000', fontSize: 10, fontFamily: 'Arial', zIndex: 2000, width: 400, lineHeight: 1 },
            // },
            
             // BACK DESIGN
            //  {
            //     type: 'rect',
            //     options: { left: 20, top: 60, width: 350, height: 440, fill: '#FAEE45', zIndex: -1000 },
            // },
            
        ],
    },
    // Add more templates as needed
]