import React, { useEffect, useState } from 'react'
import Navbar from './Navbar';
import Hanger from '../Assets/Images/hanger1.jpg'
import Tshirt from '../Assets/Images/shirt1.jpg'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { token, pixelId } from '../Api';


function Banner() {

    const navigation = useNavigate()

    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);


        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    useEffect(() => {
        axios.post(`https://graph.facebook.com/v20.0/${pixelId}/events?access_token=${token}`, {
            "data": [
                {
                    "event_name": "Home page",
                    "event_time": 1719515925,
                    "action_source": "website",
                    "event_source_url": "https://kridonvalley/",
                    "user_data": {
                        "em": [
                            "7b17fb0bd173f625b58636fb796407c22b3d16fc78302d79f0fd30c2fc2fc068"
                        ],
                        "ph": [
                            null
                        ]
                    },
                    "custom_data": {
                        "currency": "NGN",
                        "value": ""
                    }
                }
            ]

        })
            .then(function (response) {
                console.log('Facebook event', response);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])

    const [Text1, setText1] = useState(40)
    const [Text2, setText2] = useState(100)
    const [Text3, setText3] = useState(20)
    const [bannerHeight, setBannerHeight] = useState(0)
    const [imgWidth, setImgWidth] = useState(300)
    const [imgHeight, setImgHeight] = useState(200)
    const [buttonWidth, setButtonWidth] = useState(300)
    const [buttonHeight, setButtonHeight] = useState(45)
    const [marginLeft1, setMarginLeft1] = useState(300)
    const [marginLeft2, setMarginLeft2] = useState(0)
    const [marginLeft3, setMarginLeft3] = useState(-500)
    const [marginTop1, setMarginTop1] = useState(400)
    const [marginTop2, setMarginTop2] = useState(-220)
    const [marginTop3, setMarginTop3] = useState(100)

    useEffect(() => {
        if (screenSize.width < 1500) {
            setText1(40)
        }

        if (screenSize.width < 1200) {
            setText1(20)
        }

        if (screenSize.width < 600) {
            setText1(16)
        }

        if (screenSize.width > 1500) {
            setText1(40)
        }
    }, [screenSize]) //Text1

    useEffect(() => {
        if (screenSize.width < 1500) {
            setText2(100)
        }

        if (screenSize.width < 1200) {
            setText2(50)
        }

        if (screenSize.width < 600) {
            setText2(30)
        }

        if (screenSize.width > 1500) {
            setText2(100)
        }
    }, [screenSize]) //Text2

    useEffect(() => {
        if (screenSize.width < 1500) {
            setText3(20)
        }

        if (screenSize.width < 1200) {
            setText3(16)
        }

        if (screenSize.width < 600) {
            setText3(14)
        }

        if (screenSize.width > 1500) {
            setText3(20)
        }
    }, [screenSize]) //Text3

    useEffect(() => {
        if (screenSize.width < 1500) {
            setBannerHeight(950)
        }

        if (screenSize.width < 1200) {
            setBannerHeight(600)
        }

        if (screenSize.width < 600) {
            setBannerHeight(350)
        }

        if (screenSize.width > 1500) {
            setBannerHeight(950)
        }
    }, [screenSize]) // BannerHeight

    useEffect(() => {
        if (screenSize.width < 1500) {
            setImgWidth(300)
        }

        if (screenSize.width < 1200) {
            setImgWidth(250)
        }

        if (screenSize.width < 600) {
            setImgWidth(120)
        }

        if (screenSize.width > 1500) {
            setImgWidth(300)
        }
    }, [screenSize]) //ImgWidth

    useEffect(() => {
        if (screenSize.width < 1500) {
            setImgHeight(200)
        }

        if (screenSize.width < 1200) {
            setImgHeight(150)
        }

        if (screenSize.width < 600) {
            setImgHeight(80)
        }

        if (screenSize.width > 1500) {
            setImgHeight(200)
        }
    }, [screenSize]) //ImgHeight

    useEffect(() => {
        if (screenSize.width < 1500) {
            setButtonWidth(300)
        }

        if (screenSize.width < 1200) {
            setButtonWidth(250)
        }

        if (screenSize.width < 600) {
            setButtonWidth(120)
        }

        if (screenSize.width > 1500) {
            setButtonWidth(300)
        }
    }, [screenSize]) //ButtonWidth

    useEffect(() => {
        if (screenSize.width < 1500) {
            setButtonHeight(45)
        }

        if (screenSize.width < 1200) {
            setButtonHeight(40)
        }

        if (screenSize.width < 600) {
            setButtonHeight(30)
        }

        if (screenSize.width > 1500) {
            setButtonHeight(45)
        }
    }, [screenSize]) //ButtonHeight

    useEffect(() => {
        if (screenSize.width < 1500) {
            setMarginLeft1(300)
        }

        if (screenSize.width < 1200) {
            setMarginLeft1(100)
        }

        if (screenSize.width < 600) {
            setMarginLeft1(50)
        }

        if (screenSize.width > 1500) {
            setMarginLeft1(300)
        }
    }, [screenSize]) //MarginLeft1

    useEffect(() => {
        if (screenSize.width < 1500) {
            setMarginTop1(400)
        }

        if (screenSize.width < 1200) {
            setMarginTop1(200)
        }

        if (screenSize.width < 600) {
            setMarginTop1(100)
        }

        if (screenSize.width > 1500) {
            setMarginTop1(400)
        }
    }, [screenSize]) //MarginTop1

    useEffect(() => {
        if (screenSize.width < 1500) {
            setMarginLeft2(0)
        }

        if (screenSize.width < 1200) {
            setMarginLeft2(10)
        }

        if (screenSize.width < 600) {
            setMarginLeft2(40)
        }

        if (screenSize.width > 1500) {
            setMarginLeft2(0)
        }
    }, [screenSize]) //MarginLeft2

    useEffect(() => {
        if (screenSize.width < 1500) {
            setMarginTop2(-220)
        }

        if (screenSize.width < 1200) {
            setMarginTop2(-120)
        }

        if (screenSize.width < 600) {
            setMarginTop2(-80)
        }

        if (screenSize.width > 1500) {
            setMarginTop2(-220)
        }
    }, [screenSize]) //MarginTop2

    useEffect(() => {
        if (screenSize.width < 1500) {
            setMarginLeft3(-500)
        }

        if (screenSize.width < 1200) {
            setMarginLeft3(-250)
        }

        if (screenSize.width < 600) {
            setMarginLeft3(-155)
        }

        if (screenSize.width > 1500) {
            setMarginLeft3(-500)
        }
    }, [screenSize]) //marginLeft3

    useEffect(() => {
        if (screenSize.width < 1500) {
            setMarginTop3(100)
        }

        if (screenSize.width < 1200) {
            setMarginTop3(20)
        }

        if (screenSize.width < 600) {
            setMarginTop3(-20)
        }

        if (screenSize.width > 1500) {
            setMarginTop3(100)
        }
    }, [screenSize]) //marginTop3

    return (
        <div style={{ width: '100%', height: bannerHeight, overflow: 'hidden', background: 'linear-gradient(to right, #000, #3f3f3f)', }}>
            <div style={{ width: '100%', height: bannerHeight, position: 'absolute' }}>
                <img src={Hanger} alt='Cloth' style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='overlay' style={{ zIndex: 200, height: bannerHeight, display: 'flex', overflow: 'hidden' }}>
                <div style={{ width: '100%' }}>
                    <Navbar />
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', height: bannerHeight }}>
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%', height: bannerHeight }}>
                            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: marginLeft1, marginTop: marginTop1 }}>
                                <div style={{ fontSize: Text1, color: '#fff', transform: 'rotate(-450deg)', transformOrigin: 'left center', marginRight: marginLeft2 }}>STYLE</div>
                                <div style={{ fontSize: Text2, color: '#fff', transform: 'rotate(-450deg)', transformOrigin: 'left center', fontWeight: 'bold', marginLeft: -70, opacity: 0.3 }}>UNLEASHED</div>
                            </div>
                            <div style={{ fontSize: Text2, color: '#fff', marginLeft: marginLeft3, width: 150, fontWeight: 'bold', marginTop: marginTop2, opacity: 0.8 }}>
                                WHERE FASHION MEETS YOU
                            </div>
                        </div>

                        <div style={{ marginRight: 100, marginTop: marginTop3 }}>
                            <div style={{ width: imgWidth, height: imgHeight, border: '1px solid #fff', borderRadius: 20, overflow: 'hidden' }}>
                                <img src={Tshirt} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </div>
                            <div style={{ width: buttonWidth, height: buttonHeight, border: '1px solid #fff', borderRadius: 15, marginTop: 20, display: 'flex', fontSize: Text3, color: '#fff', fontWeight: 'bold', fontFamily: 'FuturaLight', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => navigation('/Shop')}>
                                SHOP NOW
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner