import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import HomeScreen from './Screens/HomeScreen';
import ShopScreen from './Screens/ShopScreen';
import ProductScreen from './Screens/ProductScreen';
import CartScreen from './Screens/CartScreen';
import CustomScreen from './Screens/CustomScreen';
import SignupScreen from './Screens/SignupScreen';
import LoginScreen from './Screens/LoginScreen';
import CheckoutScreen from './Screens/CheckoutScreen';
import ThankYou from './Screens/ThankYou';
import AccountScreen from './Screens/AccountScreen';
import EditProfileScreen from './Screens/EditProfileScreen';
import ResetPasswordScreen from './Screens/ResetPasswordScreen';

function App() {
  return (
    <div style={{width: '100%'}}>
      <Routes>
        <Route path='/' element={<HomeScreen />} />
        <Route path='/Shop' element={<ShopScreen />} />
        <Route path='/Product' element={<ProductScreen />} />
        <Route path='/Cart' element={<CartScreen />} />
        <Route path='/Custom' element={<CustomScreen />} />
        <Route path='/Signup' element={<SignupScreen />} />
        <Route path='/Login' element={<LoginScreen />} />
        <Route path='/Checkout' element={<CheckoutScreen />} />
        <Route path='/Thankyou' element={<ThankYou />} />
        <Route path='/Account' element={<AccountScreen />} />
        <Route path='/EditProfile' element={<EditProfileScreen />} />
        <Route path='/ResetPassword' element={<ResetPasswordScreen />} />
      </Routes>
    </div>
  );
}

export default App;
