import React, { useState, useEffect } from 'react'
import Navbar from '../Components/Navbar'
import { db, auth } from '../Firebase'
import { useNavigate } from 'react-router-dom'
import { CircularProgress } from '@mui/material'

function AccountScreen() {

    const navigation = useNavigate()

    const [show, setShow] = useState(0)
    const [loading, setLoading] = useState(false)
    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [state, setState] = useState('')
    const [number, setNumber] = useState('')
    const [address, setAddress] = useState('')
    const [picture, setPicture] = useState('')
    const [size, setSize] = useState(200)

    const [orders, setOrders] = useState([])
    const [savedDesign, setSavedDesign] = useState([])

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);


        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    useEffect(() => {
        if (screenSize.width < 1500) {
            setSize(200)
        }

        if (screenSize.width < 1200) {
            setSize(130)
        }

        if (screenSize.width < 600) {
            setSize(80)
        }

        if (screenSize.width > 1500) {
            setSize(200)
        }
    }, [screenSize])

    useEffect(() => {

        const timer = setTimeout(() => {
            // Your delayed code here
            db.collection('Users')
                .doc(auth.currentUser?.uid)
                .get()
                .then((snapshot) => {
                    const { FirstName, Email, State, LastName, PhoneNumber, Address, Image } = snapshot.data()
                    setFirstName(FirstName)
                    setLastName(LastName)
                    setEmail(Email)
                    setState(State)
                    setNumber(PhoneNumber)
                    setAddress(Address)
                    setPicture(Image)
                }).catch(e => {
                    console.log(e)
                })

            const query = db.collection('Orders')

            if (auth.currentUser?.uid !== undefined) {
                query.where(`userId`, `==`, auth.currentUser?.uid)
                    .get()
                    .then((snapshot) => {
                        const order = snapshot.docs.map(doc => {
                            const data = doc.data()
                            const id = doc.id
                            return { data, id }
                        })
                        setOrders(order)
                    }).catch(e => {
                        console.log(e)
                    })
            }


            const query1 = db.collection('SavedDesign')

            if (auth.currentUser?.uid !== undefined) {
                query1.where(`UserId`, `==`, auth.currentUser?.uid)
                    .get()
                    .then((snapshot) => {
                        const saved = snapshot.docs.map(doc => {
                            const data = doc.data()
                            const id = doc.id
                            return { data, id }
                        })
                        setSavedDesign(saved)
                    }).catch(e => {
                        console.log(e)
                    })
            }
        }, 3000); // 3000 milliseconds = 3 seconds

        // Cleanup the timer if the component unmounts before the delay completes
        return () => clearTimeout(timer);

    }, [auth.currentUser?.uid])

    const logout = () => {
        setLoading(true)
        auth.signOut().then(() => {
            setLoading(false)
            navigation('/Login')
        })
    }

    const MyProfile = () => {
        return (
            <div className='col-12' style={{ marginLeft: '4vw', display: 'flex', justifyContent: 'space-between', marginTop: 40, marginBottom: 50, flexDirection: screenSize.width < 600 ? 'column' : 'row' }}>
                <div style={{ display: 'flex', flexDirection: screenSize.width < 600 ? 'column' : 'row' }}>
                    <div style={{ width: size, height: size, backgroundColor: '#eee', borderRadius: 200 }}>
                        <img src={picture} style={{ width: '100%', objectFit: 'contain' }} />
                    </div>
                    <div style={{ marginLeft: '1.5vw', marginTop: 15 }}>
                        <p style={{ fontSize: 20, fontFamily: 'FuturaLight', color: '#384A62', fontWeight: 'bold' }}>{firstName} {lastName}</p>
                        <p style={{ fontSize: 20, fontFamily: 'FuturaLight', color: '#384A62', fontWeight: 'bold' }}>{email}</p>
                        <p style={{ fontSize: 20, fontFamily: 'FuturaLight', color: '#384A62', fontWeight: 'bold' }}>{number}</p>
                        <div>
                            <p style={{ color: '#03B3C1', cursor: 'pointer', fontSize: 18, fontFamily: 'FuturaLight', fontWeight: 'bold' }} onClick={() => navigation('/EditProfile')}>Edit</p>
                        </div>
                        <div>
                            {loading == false ? (
                                <p style={{ color: 'red', cursor: 'pointer', ontSize: 18, fontFamily: 'FuturaLight', fontWeight: 'bold' }} onClick={logout}>Logout</p>
                            ) : (
                                <CircularProgress />
                            )}
                        </div>
                    </div>
                </div>
                <div className='col-6'>
                    <div>
                        <p style={{ fontFamily: 'FuturaLight', color: '#384A62', fontSize: 25, fontWeight: 'bold' }}>Shipping Information</p>
                    </div>
                    <div style={{ width: 330, height: 120, backgroundColor: '#FCFBF9', border: '1px solid #ccc' }}>
                        <div style={{ marginLeft: 10 }}>
                            <p style={{ fontSize: 18, fontFamily: 'FuturaLight', color: '#384A62' }}>{state}</p>
                            <p style={{ fontSize: 14, fontFamily: 'FuturaLight', color: '#384A62', width: 320 }}>{address}</p>
                        </div>
                    </div>
                    <div style={{ width: 300, height: 40, backgroundColor: '#000', display: 'flex', alignItems: 'center', marginTop: 20, cursor: 'pointer', fontSize: 18, fontFamily: 'FuturaLight', color: '#fff', marginLeft: 10, justifyContent: 'center', fontWeight: 'bold' }} onClick={() => navigation('/EditAddress')}>
                        Edit shipping address
                    </div>
                </div>
            </div>
        )
    }

    const OrderHistory = () => {
        return (
            <div style={{ marginBottom: 50 }}>
                <div style={{ marginLeft: 20 }}>
                    <p style={{ fontSize: 18, fontFamily: 'RobotoBold', color: '#384A62' }}>This is a list of all your purchases with us so far</p>
                </div>
                <div style={{ width: '100%', height: 60, backgroundColor: '#F9FAFB', display: 'flex', justifyContent: 'space-between', marginLeft: 20, alignItems: 'center' }}>
                    <div style={{ marginLeft: 10, width: 100 }}>
                        <p style={{ fontSize: 12, color: '#384A62' }}>NO</p>
                    </div>
                    <div style={{ marginLeft: 10, width: 100 }}>
                        <p style={{ fontSize: 12, color: '#384A62' }}>ORDER</p>
                    </div>
                    <div style={{ width: 100 }}>
                        <p style={{ fontSize: 12, color: '#384A62' }}>DATE</p>
                    </div>
                    <div style={{ width: 100 }}>
                        <p style={{ fontSize: 12, color: '#384A62' }}>QTY</p>
                    </div>
                    <div style={{ width: 100 }}>
                        <p style={{ fontSize: 12, color: '#384A62' }}>AM</p>
                    </div>
                    <div style={{ marginRight: 50, width: 100 }}>
                        <p style={{ fontSize: 12, color: '#384A62' }}>STATS</p>
                    </div>
                </div>
                {orders.map((items, index) => (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginLeft: 20, alignItems: 'center' }}>
                        <div style={{ marginLeft: 10, width: 100 }}>
                            <p style={{ fontSize: 12, color: '#384A62' }}>{index + 1}</p>
                        </div>
                        <div style={{ marginLeft: 10, width: 100 }}>
                            <p style={{ fontSize: 12, color: '#384A62' }}>{items.data?.name}</p>
                        </div>
                        <div style={{ width: 100 }}>
                            <p style={{ fontSize: 12, color: '#384A62' }}>Date</p>
                        </div>
                        <div style={{ width: 100 }}>
                            <p style={{ fontSize: 12, color: '#384A62' }}>{items.data?.quantity}</p>
                        </div>
                        <div style={{ width: 100 }}>
                            <p style={{ fontSize: 12, color: '#384A62' }}>₦{items.data?.price}</p>
                        </div>
                        <div style={{ marginRight: 50, width: 100 }}>
                            <p style={{ fontSize: 12, color: '#384A62' }}>{items.data?.status}</p>
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div className='col-12'>
            <Navbar />
            <div style={{ marginLeft: '4vw' }}>
                <p style={{ fontSize: 25, fontFamily: 'RobotoBold', color: '#384A62' }}>My Account</p>
            </div>
            <div className='col-12' style={{ marginLeft: '2vw', border: '1px solid #ccc', borderLeftWidth: 0, borderRightWidth: 0, borderTopWidth: 0 }}>
                <div style={{ display: 'flex', justifyContent: 'space-around', }} className='col-12'>
                    <div>
                        <p style={{ color: show == 0 ? '#000' : '#B0B8C2', fontFamily: 'RobotoBold', cursor: 'pointer' }} onClick={() => setShow(0)}>My Profile</p>
                    </div>

                    <div>
                        <p style={{ color: show == 2 ? '#000' : '#B0B8C2', fontFamily: 'RobotoBold', cursor: 'pointer' }} onClick={() => setShow(2)}>Order History</p>
                    </div>
                </div>
            </div>
            {/* <MyProfile /> */}

            {show == 0 ? (
                <MyProfile />
            ) : (
                <></>
            )}

            {show == 2 ? (
                <OrderHistory />
            ) : (
                <></>
            )}
        </div>
    )
}

export default AccountScreen