import React from 'react'
import MailIcon from '@mui/icons-material/Mail';
import { useNavigate } from 'react-router-dom';

function ThankYou() {

    const navigation = useNavigate()

    return (
        <div style={{ width: '100%' }}>
            <div style={{ width: '100%', display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 250 }}>
                <div>
                    <p style={{ fontSize: 45, fontFamily: 'RobotoBold' }}>Thank You</p>
                </div>
                <div>
                    <p style={{ fontSize: 25, fontFamily: 'RobotoLight' }}>Your order was completed successfully</p>
                </div>
                <div style={{ display: 'flex', width: 500 }}>
                    <div>
                        <MailIcon style={{ fontSize: 50 }} />
                    </div>
                    <div style={{ marginLeft: 30 }}>
                        <p>An email about details of your order has been sent to the email address provided by you. please keep it for your records</p>
                    </div>
                </div>
                <div style={{ width: 150, height: 45, backgroundColor: '#03B3C1', marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => navigation('/')}>
                    <p style={{ marginTop: 15, color: '#fff' }}>Return Home</p>
                </div>
            </div>
        </div>
    )
}

export default ThankYou