import React, { useState, useEffect } from 'react'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Navbar from '../Components/Navbar'
import Shirt from '../Assets/Images/tshirt.png'
import { useNavigate } from 'react-router-dom';
import { Product } from '../Components/data';
import { db } from '../Firebase';
import axios from 'axios';
import { token, pixelId } from '../Api';

function ShopScreen() {

    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    const navigation = useNavigate()

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);


        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    const [caseWidth, setCaseWidth] = useState(0)
    const [caseHeight, setCaseHeight] = useState(0)
    const [productSize, setProductSize] = useState(0)
    const [headerText, setHeaderText] = useState(0)

    const [products, setProducts] = useState([])

    useEffect(() => {
        axios.post(`https://graph.facebook.com/v20.0/${pixelId}/events?access_token=${token}`, {
            "data": [
                {
                    "event_name": "Shop page",
                    "event_time": 1719515925,
                    "action_source": "website",
                    "event_source_url": "https://kridonvalley/Shop",
                    "user_data": {
                        "em": [
                            "7b17fb0bd173f625b58636fb796407c22b3d16fc78302d79f0fd30c2fc2fc068"
                        ],
                        "ph": [
                            null
                        ]
                    },
                    "custom_data": {
                        "currency": "NGN",
                        "value": ""
                    }
                }
            ]

        })
            .then(function (response) {
                console.log('Facebook event', response);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])

    useEffect(() => {
        if (screenSize.width < 1500) {
            setCaseWidth(400)
        }

        if (screenSize.width < 1200) {
            setCaseWidth(250)
        }

        if (screenSize.width < 600) {
            setCaseWidth(180)
        }

        if (screenSize.width > 1500) {
            setCaseWidth(400)
        }
    }, [screenSize])

    useEffect(() => {
        if (screenSize.width < 1500) {
            setCaseHeight(485)
        }

        if (screenSize.width < 1200) {
            setCaseHeight(385)
        }

        if (screenSize.width < 600) {
            setCaseHeight(285)
        }

        if (screenSize.width > 1500) {
            setCaseHeight(485)
        }
    }, [screenSize])

    useEffect(() => {
        if (screenSize.width < 1500) {
            setProductSize(400)
        }

        if (screenSize.width < 1200) {
            setProductSize(250)
        }

        if (screenSize.width < 600) {
            setProductSize(180)
        }

        if (screenSize.width > 1500) {
            setProductSize(400)
        }
    }, [screenSize])

    useEffect(() => {
        if (screenSize.width < 1500) {
            setHeaderText(40)
        }

        if (screenSize.width < 1200) {
            setHeaderText(30)
        }

        if (screenSize.width < 600) {
            setHeaderText(20)
        }

        if (screenSize.width > 1500) {
            setHeaderText(40)
        }
    }, [screenSize])

    useEffect(() => {
       db.collection('Products')
       .get()
       .then((snapshot) => {
          const pro = snapshot.docs.map(doc => {
             const data = doc.data()
             const id = doc.id
             return {data, id}
          })
          setProducts(pro)
       })
    }, [])

    return (
        <div style={{ width: '100%' }}>
            <div style={{ width: '100%', height: 80, backgroundColor: '#3F3F3F' }}>
                <Navbar />
            </div>
            <div style={{ width: '100%' }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 50 }}>
                    <div style={{ fontSize: headerText, fontFamily: 'FuturaLight', color: '#000', marginLeft: 20 }}>NEW ARRIVALS: SHOES, CLOTHING & MORE</div>
                    <div style={{ width: 110, height: screenSize.width < 600 ? 35 : 45, border: '1px solid #000', marginRight: screenSize.width < 600 ? 10 : 60, display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#000', fontFamily: 'FuturaLight', cursor: 'pointer', fontSize: screenSize.width < 600 ? 10 : 16 }}>
                        Filter & Sort
                    </div>
                </div>
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ width: '98%', marginTop: 20, display: 'flex', flexWrap: 'wrap', gap: 3, alignItems: 'center', justifyContent: 'flex-start', marginLeft: screenSize.width < 600 ? 25 : 0 }}>
                        {products.map((item, index) => (
                            <div style={{ width: caseWidth, height: caseHeight, cursor: 'pointer' }} key={index} onClick={() => navigation('/Product',{state: {id: item.id}})}>
                                <div style={{ width: productSize, height: productSize, backgroundColor: '#EAEEEF', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div style={{ position: 'absolute', right: 5, top: 5 }}><FavoriteBorderIcon style={{ fontSize: 20, color: '#000' }} /></div>
                                    <div style={{ fontSize: 18, color: '#000', fontFamily: 'FuturaLight', position: 'absolute', fontWeight: 'bold', bottom: 5, left: 5 }}>₦{(item.data?.Price).toLocaleString()}</div>
                                    <img src={item.data?.Image} style={{ width: '90%', height: '90%', objectFit: 'cover' }} />
                                </div>
                                <div style={{ fontSize: 20, fontFamily: 'FuturaLight', color: '#000', fontWeight: 'bold', marginTop: 10 }}>{item.data.Name}</div>
                                <div style={{ fontSize: 18, fontFamily: 'FuturaLight', color: '#000', marginTop: 10 }}>{item.data.Category}</div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ShopScreen