import React, { useState, useEffect } from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import Logo from '../Assets/Images/whitelogo.png'

function Footer() {

  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);


    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize])

  const redirectToInstagram = () => {
    window.location.href = 'https://www.instagram.com/kridonvalley?igsh=aWFqZHBzaW1xMWVh&utm_source=qr'; // or use window.location.replace(url);
};

const redirectToFacebook = () => {
    window.location.href = 'https://web.facebook.com/profile.php?id=61561281083758'; // or use window.location.replace(url);
};

  return (
    <div style={{ width: '100%', height: screenSize.width < 700 ? 400 : 800, backgroundColor: '#1C1C1A', display: 'flex', justifyContent: 'center', marginTop: 20 }}>
      {screenSize.width < 700 ? (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{marginBottom: 20}}>
              <img src={Logo} style={{ width: 100, height: 100, objectFit: 'contain' }} />
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <div style={{ fontSize: 10, color: '#fff' }}>ABOUT KRIDONVALLEY</div>
              <div style={{ fontSize: 10, color: '#fff' }}>OUR DESTINATIONS</div>
              <div style={{ fontSize: 10, color: '#fff' }}>OFFERS</div>
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <div style={{ fontSize: 10, color: '#fff' }}>CONTACT</div>
              <div style={{ fontSize: 10, color: '#fff', fontFamily: 'GothamLight' }}>+2348134556675</div>
            <div style={{ fontSize: 10, color: '#fff', fontFamily: 'GothamLight', }}>info@kridonvalley.com</div>
            </div>
          </div>
          <div>
            <div style={{ display: 'flex' }}>
              <div><FacebookIcon style={{ fontSize: 40, color: '#fff' }} /></div>
              <div style={{ marginLeft: 10 }}><XIcon style={{ fontSize: 40, color: '#fff' }} /></div>
              <div style={{ marginLeft: 10 }}><InstagramIcon style={{ fontSize: 40, color: '#fff' }} /></div>
            </div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <div style={{marginTop: 100}}>
            <img src={Logo} style={{ width: 300, height: 300, objectFit: 'contain' }} />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ fontSize: 17, color: '#fff', fontFamily: 'GothamLight' }}>+2348134556675</div>
            <div style={{ height: 30, width: 0.5, backgroundColor: '#fff', marginLeft: 20 }}></div>
            <div style={{ fontSize: 17, color: '#fff', fontFamily: 'GothamLight', marginLeft: 20 }}>No 24 Jose Martins Street, Asokoro Abuja.</div>
            <div style={{ height: 30, width: 0.5, backgroundColor: '#fff', marginLeft: 20 }}></div>
            <div style={{ fontSize: 17, color: '#fff', fontFamily: 'GothamLight', marginLeft: 20 }}>info@kridonvalley.com</div>
          </div>
          <div style={{ width: '40%', display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: 30 }}>
            <div style={{ fontSize: 17, color: '#fff', fontFamily: 'GothamLight' }}>PRIVACY POLICY</div>
            <div style={{ fontSize: 17, color: '#fff', fontFamily: 'GothamLight' }}>TERMS & CONDITIONS</div>
          </div>
          <div style={{ width: '100%', height: 0.5, backgroundColor: '#fff', marginTop: 150 }}></div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
            <div style={{ width: 80, height: 80, border: '0.5px solid #fff', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={redirectToFacebook}>
              <FacebookIcon style={{ fontSize: 30, color: '#fff' }} />
            </div>
            <div style={{ width: 80, height: 80, border: '0.5px solid #fff', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={redirectToInstagram}>
              <InstagramIcon style={{ fontSize: 30, color: '#fff' }} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Footer