import React from 'react'
import Banner from '../Components/Banner'
import Intrested from '../Components/Intrested'
import Category from '../Components/Category'
import AboutUs from '../Components/AboutUs'
import Footer from '../Components/Footer'

function HomeScreen() {
  return (
    <div style={{width: '100%'}}>
      <Banner />
      <Intrested />
      <Category />
      <AboutUs />
      <Footer />
    </div>
  )
}

export default HomeScreen