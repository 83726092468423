export const Product = [
    {
        id: 1,
        image: require('../Assets/Images/tshirt.png'),
        name: 'Brown Tshirt',
        color: 'White',
        category: 'T-shirt',
        description: `is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`,
        material: 'Pilot',
        made: 'Made in Italy',
        reviews: '',
        price: 9000,
        sizeUs: [{number: 20},{number: 30},{number: 35},{number: 45},{number: 55}],
        sizeEu: [{number: 1},{number: 2},{number: 3},{number: 4},{number: 5}],
        sizeUk: [{number: 5},{number: 10},{number: 15},{number: 20},{number: 25}],
        images: [
            {
                image: require('../Assets/Images/cloth.jpg'),
            },
            {
                image: require('../Assets/Images/cloth.jpg'),
            },
            {
                image: require('../Assets/Images/cloth.jpg'),
            },
        ]
    },
    {
        id: 2,
        image: require('../Assets/Images/tshirt1.png'),
        name: 'Brown Tshirt',
        color: 'White',
        category: 'T-shirt',
        description: `is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`,
        material: 'Pilot',
        made: 'Made in Italy',
        reviews: '',
        price: 9000,
        sizeUs: [{number: 20},{number: 30},{number: 35},{number: 45},{number: 55}],
        sizeEu: [{number: 1},{number: 2},{number: 3},{number: 4},{number: 5}],
        sizeUk: [{number: 5},{number: 10},{number: 15},{number: 20},{number: 25}],
        images: [
            {
                image: require('../Assets/Images/cloth.jpg'),
            },
            {
                image: require('../Assets/Images/cloth.jpg'),
            },
            {
                image: require('../Assets/Images/cloth.jpg'),
            },
        ]
    },
    {
        id: 3,
        image: require('../Assets/Images/tshirt.png'),
        name: 'Brown Tshirt',
        color: 'White',
        category: 'T-shirt',
        description: `is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`,
        material: 'Pilot',
        made: 'Made in Italy',
        reviews: '',
        price: 9000,
        sizeUs: [{number: 20},{number: 30},{number: 35},{number: 45},{number: 55}],
        sizeEu: [{number: 1},{number: 2},{number: 3},{number: 4},{number: 5}],
        sizeUk: [{number: 5},{number: 10},{number: 15},{number: 20},{number: 25}],
        images: [
            {
                image: require('../Assets/Images/cloth.jpg'),
            },
            {
                image: require('../Assets/Images/cloth.jpg'),
            },
            {
                image: require('../Assets/Images/cloth.jpg'),
            },
        ]
    },
    {
        id: 4,
        image: require('../Assets/Images/tshirt1.png'),
        name: 'Brown Tshirt',
        color: 'White',
        category: 'T-shirt',
        description: `is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`,
        material: 'Pilot',
        made: 'Made in Italy',
        reviews: '',
        price: 9000,
        sizeUs: [{number: 20},{number: 30},{number: 35},{number: 45},{number: 55}],
        sizeEu: [{number: 1},{number: 2},{number: 3},{number: 4},{number: 5}],
        sizeUk: [{number: 5},{number: 10},{number: 15},{number: 20},{number: 25}],
        images: [
            {
                image: require('../Assets/Images/cloth.jpg'),
            },
            {
                image: require('../Assets/Images/cloth.jpg'),
            },
            {
                image: require('../Assets/Images/cloth.jpg'),
            },
        ]
    },
    {
        id: 5,
        image: require('../Assets/Images/cloth.jpg'),
        name: 'Brown Tshirt',
        color: 'White',
        category: 'T-shirt',
        description: `is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`,
        material: 'Pilot',
        made: 'Made in Italy',
        reviews: '',
        price: 9000,
        sizeUs: [{number: 20},{number: 30},{number: 35},{number: 45},{number: 55}],
        sizeEu: [{number: 1},{number: 2},{number: 3},{number: 4},{number: 5}],
        sizeUk: [{number: 5},{number: 10},{number: 15},{number: 20},{number: 25}],
        images: [
            {
                image: require('../Assets/Images/cloth.jpg'),
            },
            {
                image: require('../Assets/Images/cloth.jpg'),
            },
            {
                image: require('../Assets/Images/cloth.jpg'),
            },
        ]
    },
    {
        id: 6,
        image: require('../Assets/Images/cloth.jpg'),
        name: 'Brown Tshirt',
        color: 'White',
        category: 'T-shirt',
        description: `is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`,
        material: 'Pilot',
        made: 'Made in Italy',
        reviews: '',
        price: 9000,
        sizeUs: [{number: 20},{number: 30},{number: 35},{number: 45},{number: 55}],
        sizeEu: [{number: 1},{number: 2},{number: 3},{number: 4},{number: 5}],
        sizeUk: [{number: 5},{number: 10},{number: 15},{number: 20},{number: 25}],
        images: [
            {
                image: require('../Assets/Images/cloth.jpg'),
            },
            {
                image: require('../Assets/Images/cloth.jpg'),
            },
            {
                image: require('../Assets/Images/cloth.jpg'),
            },
        ]
    },
    {
        id: 7,
        image: require('../Assets/Images/cloth.jpg'),
        name: 'Brown Tshirt',
        color: 'White',
        category: 'T-shirt',
        description: `is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`,
        material: 'Pilot',
        made: 'Made in Italy',
        reviews: '',
        price: 9000,
        sizeUs: [{number: 20},{number: 30},{number: 35},{number: 45},{number: 55}],
        sizeEu: [{number: 1},{number: 2},{number: 3},{number: 4},{number: 5}],
        sizeUk: [{number: 5},{number: 10},{number: 15},{number: 20},{number: 25}],
        images: [
            {
                image: require('../Assets/Images/cloth.jpg'),
            },
            {
                image: require('../Assets/Images/cloth.jpg'),
            },
            {
                image: require('../Assets/Images/cloth.jpg'),
            },
        ]
    }
]

export const category = [
    {
        id: 1,
        name: 'T-shirt',
        image: require('../Assets/Images/shop1.jpg')
    },
    {
        id: 2,
        name: 'T-shirt',
        image: require('../Assets/Images/shop2.jpg')
    },
    {
        id: 3,
        name: 'T-shirt',
        image: require('../Assets/Images/shop3.jpg')
    },
    {
        id: 4,
        name: 'T-shirt',
        image: require('../Assets/Images/shop4.jpg')
    },
]