import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { useNavigate, useLocation } from 'react-router-dom';
import { Product } from '../Components/data';
import { auth, db } from '../Firebase';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CircularProgress } from '@mui/material';
import axios from 'axios';
import { token, pixelId } from '../Api';

function ProductScreen() {

    const navigation = useNavigate()
    const location = useLocation()
    const id = location.state?.id
    const [loading, setLoading] = useState(false)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);

    const [us, setUs] = useState(false)
    const [eu, setEu] = useState(true)
    const [uk, setUk] = useState(false)

    // All products information
    const [category, setCategory] = useState('')
    const [name, setName] = useState('')
    const [color, setColor] = useState('')
    const [price, setPrice] = useState('')
    const [qtyPrice, setQtyPrice] = useState(0)
    const [made, setMade] = useState('')
    const [description, setDescription] = useState('')
    const [material, setMaterial] = useState('')
    const [image, setImage] = useState('')
    const [templateId, setTemplateId] = useState(null)
    const [images, setImages] = useState([])
    const [sizeEu, setSizeEu] = useState([])
    const [sizeUk, setSizeUk] = useState([])
    const [sizeUs, setSizeUs] = useState([])

    const [selectedSize, setSelectedSize] = useState('');
    const [selectedError, setSelectedError] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState('')
    const [qty, setQty] = useState(1)

    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);


        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    const [height1, setHeight1] = useState(window.innerHeight - 70)

    useEffect(() => {
        if (screenSize.width < 1500) {
            setHeight1(window.innerHeight - 70)
        }

        if (screenSize.width < 1200) {
            setHeight1(window.innerHeight - 200)
        }

        if (screenSize.width < 600) {
            setHeight1(window.innerHeight - 400)
        }

        if (screenSize.width > 1500) {
            setHeight1(window.innerHeight - 70)
        }
    }, [screenSize]) //height1

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const toggleDropdown1 = () => {
        setIsOpen1(!isOpen1);
    };

    const toggleDropdown2 = () => {
        setIsOpen2(!isOpen2);
    };

    const usButton = () => {
        setUs(true)
        setEu(false)
        setUk(false)
    }

    const euButton = () => {
        setEu(true)
        setUs(false)
        setUk(false)
    }

    const ukButton = () => {
        setUk(true)
        setEu(false)
        setUs(false)
    }

    useEffect(() => {
        db.collection('Products')
            .doc(id)
            .get()
            .then((snapshot) => {
                const { Name, Category, Color, Description, Made, Material, Price, Quantity, Reviews, Image, TemplateId } = snapshot.data()
                setName(Name)
                setCategory(Category)
                setColor(Color)
                setDescription(Description)
                setMade(Made)
                setMaterial(Material)
                setPrice(Price)
                setImage(Image)
                setTemplateId(TemplateId)
            }).catch(e => {
                console.log(e)
            })
    }, [id])

    useEffect(() => {
        db.collection('Products')
            .doc(id)
            .collection('images')
            .get()
            .then((snapshot) => {
                const img = snapshot.docs.map(doc => {
                    const data = doc.data()
                    const id = doc.id
                    return { data, id }
                })
                setImages(img)
            })
    }, [id])

    useEffect(() => {
        db.collection('Products')
            .doc(id)
            .collection('sizeEu')
            .get()
            .then((snapshot) => {
                const eu = snapshot.docs.map(doc => {
                    const data = doc.data()
                    const id = doc.id
                    return { data, id }
                })
                setSizeEu(eu)
            }).catch(e => {
                console.log(e)
            })

        db.collection('Products')
            .doc(id)
            .collection('sizeUs')
            .get()
            .then((snapshot) => {
                const us = snapshot.docs.map(doc => {
                    const data = doc.data()
                    const id = doc.id
                    return { data, id }
                })
                setSizeUs(us)
            }).catch(e => {
                console.log(e)
            })

        db.collection('Products')
            .doc(id)
            .collection('sizeUk')
            .get()
            .then((snapshot) => {
                const uk = snapshot.docs.map(doc => {
                    const data = doc.data()
                    const id = doc.id
                    return { data, id }
                })
                setSizeUk(uk)
            }).catch(e => {
                console.log(e)
            })
    }, [id])

    useEffect(() => {
        setQtyPrice(price * qty)
        console.log('Main price', price * qty)
    }, [price, qty])

    const addToCart = () => {
        if (auth.currentUser?.uid) {
            if (selectedSize !== '') {
                setLoading(true)
                db.collection('Users')
                    .doc(auth.currentUser?.uid)
                    .collection('Cart')
                    .add({
                        Name: name,
                        Category: category,
                        //Description: description,
                        Material: material,
                        Price: qtyPrice,
                        Image: image,
                        ProductId: id,
                        Size: selectedSize,
                        CountrySize: selectedCountry,
                        Quantity: qty
                    }).then(() => {
                        setLoading(false)
                        facebookCartEvent()
                        navigation('/Cart')
                    }).catch(e => {
                        console.log(e)
                    })
            } else {
                setSelectedError(true)
            }
        } else {
            handleShow()
        }
    }

    const facebookCartEvent = () => {
        axios.post(`https://graph.facebook.com/v20.0/${pixelId}/events?access_token=${token}`, {
            "data": [
                {
                    "event_name": "Add to cart",
                    "event_time": 1719515925,
                    "action_source": "website",
                    "event_source_url": "https://kridonvalley/Product",
                    "user_data": {
                        "em": [
                            "7b17fb0bd173f625b58636fb796407c22b3d16fc78302d79f0fd30c2fc2fc068"
                        ],
                        "ph": [
                            null
                        ]
                    },
                    "custom_data": {
                        "currency": "NGN",
                        "value": ""
                    }
                }
            ]

        })
            .then(function (response) {
                console.log('Facebook event', response);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        axios.post(`https://graph.facebook.com/v20.0/${pixelId}/events?access_token=${token}`, {
            "data": [
                {
                    "event_name": "Product page View",
                    "event_time": 1719515925,
                    "action_source": "website",
                    "event_source_url": "https://kridonvalley/Shop",
                    "user_data": {
                        "em": [
                            "7b17fb0bd173f625b58636fb796407c22b3d16fc78302d79f0fd30c2fc2fc068"
                        ],
                        "ph": [
                            null
                        ]
                    },
                    "custom_data": {
                        "currency": "NGN",
                        "value": ""
                    }
                }
            ]

        })
            .then(function (response) {
                console.log('Facebook event', response);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])

    const handleSizeClick = (size, country) => {
        setSelectedSize(size);
        setSelectedCountry(country)
        console.log('Size', country)
    };

    const handleQty = (e) => {
        console.log('qty', e.target.value)
        setQty(e.target.value)
    }

    const generateOptions = () => {
        const options = [];

        // Add options from 1 to 10
        for (let i = 1; i <= 10; i++) {
            options.push(<option key={i} value={i}>{i}</option>);
        }

        // Add options from 10 to 100 in increments of 10
        for (let i = 10; i <= 100; i += 10) {
            if (i > 10) { // Skip 10 as it is already added in the previous loop
                options.push(<option key={i} value={i}>{i}</option>);
            }
        }

        return options;
    };

    return (
        <div style={{ width: '100%' }}>
            <Navbar />
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Login</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    You need to login to be able to add to cart
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => navigation('/Login')}>Login</Button>
                </Modal.Footer>
            </Modal>
            <div style={{ width: '100%', display: 'flex', flexDirection: screenSize.width < 1200 ? 'column' : 'row' }}>
                <div style={{ width: screenSize.width < 1200 ? '100%' : '75%', height: height1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ width: '98%', height: '70%', border: '2px solid #fff', backgroundColor: '#EEEEEE', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={images[0]?.data?.image} style={{ width: '80%', height: '90%', objectFit: 'contain' }} />
                    </div>
                    <div style={{ width: '98%', height: '30%', border: '2px solid #fff', display: 'flex', overflowX: 'scroll', gap: 5 }}>
                        {images.map((item, index) => (
                            <div style={{ width: '33%', height: '98%', backgroundColor: '#EEEEEE', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img src={item.data?.image} style={{ width: '80%', height: '90%', objectFit: 'contain' }} />
                            </div>
                        ))}
                    </div>
                </div>
                <div style={{ width: screenSize.width < 1200 ? '100%' : '25%', height: window.innerHeight - 70 }}>
                    <div style={{ fontSize: 16, color: '#000', fontFamily: 'FuturaLight', fontWeight: 'bold', marginLeft: 10 }}>{category}</div>
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: 10, marginTop: 10 }}>
                        <div style={{ fontSize: 30, color: '#000', fontFamily: 'FuturaLight', fontWeight: 'bold' }}>{name}</div>
                        <div style={{ width: 1, height: 30, backgroundColor: '#000', marginLeft: 5 }}></div>
                        <div style={{ fontSize: 30, color: '#000', fontFamily: 'FuturaLight', fontWeight: 'bold', marginLeft: 5 }}>{color}</div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: 10 }}>
                        <div style={{ display: 'flex' }}>
                            <StarOutlinedIcon style={{ fontSize: 18, color: '#000' }} />
                            <StarOutlinedIcon style={{ fontSize: 18, color: '#000' }} />
                            <StarOutlinedIcon style={{ fontSize: 18, color: '#000' }} />
                            <StarOutlinedIcon style={{ fontSize: 18, color: '#000' }} />
                            <StarOutlinedIcon style={{ fontSize: 18, color: '#000' }} />
                        </div>
                        <div style={{ fontSize: 18, fontFamily: 'FuturaLight', marginLeft: 15, fontWeight: 'bold' }}>
                            200 Reviews
                        </div>
                    </div>
                    <div style={{ fontSize: 20, fontFamily: 'FuturaLight', fontWeight: 'bold', marginLeft: 10, marginTop: 20 }}>
                        ₦{price.toLocaleString()}
                    </div>
                    <div style={{ marginLeft: 10, marginTop: 20, display: 'flex', alignItems: 'center' }}>
                        <div style={{ fontSize: 14, fontFamily: 'FuturaLight', fontWeight: 'bold' }}>SIZE</div>
                        <div style={{ width: 1, height: 20, backgroundColor: '#000', marginLeft: 10 }}></div>
                        {/* <div style={{ fontSize: 14, fontFamily: 'FuturaLight', fontWeight: us == true ? 'bold' : '', marginLeft: 10, cursor: 'pointer' }} onClick={() => usButton()}>US</div> */}
                        <div style={{ fontSize: 14, fontFamily: 'FuturaLight', fontWeight: eu == true ? 'bold' : '', marginLeft: 10, cursor: 'pointer' }} onClick={() => euButton()}>SIZE</div>
                        {/* <div style={{ fontSize: 14, fontFamily: 'FuturaLight', fontWeight: uk == true ? 'bold' : '', marginLeft: 10, cursor: 'pointer' }} onClick={() => ukButton()}>UK</div> */}
                    </div>
                    {us == true ? (
                        <div style={{ width: '95%', display: 'flex', flexWrap: 'wrap', gap: 2, marginLeft: 10, marginTop: 10 }}>
                            {sizeUs.map((item, index) => (
                                <div style={{ width: 35, height: 35, backgroundColor: selectedSize === item.data?.number ? '#000' : '#EEE', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 14, color: selectedSize === item.data?.number ? '#FFF' : '#000', fontFamily: 'FuturaLight', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleSizeClick(item.data?.number, 'US')}>{item.data?.number}</div>
                            ))}
                        </div>
                    ) : (
                        <></>
                    )}

                    {eu == true ? (
                        <div style={{ width: '95%', display: 'flex', flexWrap: 'wrap', gap: 2, marginLeft: 10, marginTop: 10 }}>
                            {sizeEu.map((item, index) => (
                                <div style={{ width: 35, height: 35, backgroundColor: selectedSize === item.data?.number ? '#000' : '#EEE', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 14, color: selectedSize === item.data?.number ? '#FFF' : '#000', fontFamily: 'FuturaLight', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleSizeClick(item.data?.number, 'EU')}>{item.data?.number}</div>
                            ))}
                        </div>
                    ) : (
                        <></>
                    )}

                    {uk == true ? (
                        <div style={{ width: '95%', display: 'flex', flexWrap: 'wrap', gap: 2, marginLeft: 10, marginTop: 10 }}>
                            {sizeUk.map((item, index) => (
                                <div style={{ width: 35, height: 35, backgroundColor: selectedSize === item.data?.number ? '#000' : '#EEE', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 14, color: selectedSize === item.data?.number ? '#FFF' : '#000', fontFamily: 'FuturaLight', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleSizeClick(item.data?.number, 'UK')}>{item.data?.number}</div>
                            ))}
                        </div>
                    ) : (
                        <></>
                    )}
                    {selectedError == false ? (
                        <></>
                    ) : (
                        <div style={{ fontSize: 18, fontFamily: 'FuturaLight', marginLeft: 15, fontWeight: 'bold', color: 'red' }}>
                            Please select a size
                        </div>
                    )}
                    <div style={{ width: '95%', height: 45, backgroundColor: '#000', marginLeft: 10, marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 18, fontFamily: 'FuturaLight', color: '#fff', fontWeight: 'bold', cursor: 'pointer' }} onClick={addToCart}>
                        {loading == false ? (
                            <>ADD TO CART</>
                        ) : (
                            <CircularProgress style={{ fontSize: 20, color: '#fff' }} />
                        )}
                    </div>
                    {/* <div style={{ width: '95%', height: 45, backgroundColor: '#000', marginLeft: 10, marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 18, fontFamily: 'FuturaLight', color: '#fff', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => navigation('/Custom', { state: { templateId: templateId } })}>
                        CUSTOMIZE
                    </div> */}
                    <div style={{ width: '95%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: 10, marginTop: 20, }}>
                        <div style={{ fontSize: 18, fontFamily: 'Futuralight', fontWeight: 'bold', color: '#000' }}>QTY:</div>
                        <select style={{ width: '75%', height: 45, border: '1px solid #000' }} onChange={handleQty}>
                            {generateOptions()}
                        </select>
                    </div>
                    <div style={{ width: '93%', height: 45, backgroundColor: '#eee', marginLeft: 10, marginTop: 20, display: 'flex', alignItems: 'center', paddingLeft: 10, fontSize: 18, color: '#000', fontFamily: 'FuturaLight', fontWeight: 'bold' }}>
                        {made}
                    </div>
                    <div style={{ width: '93%', height: 45, backgroundColor: '#eee', marginLeft: 10, marginTop: 20, display: 'flex', alignItems: 'center', paddingLeft: 10, fontSize: 16, color: '#000', fontFamily: 'FuturaLight', fontWeight: 'bold' }}>
                        Free shippping nationwide for orders ₦200,000+
                    </div>
                    <div style={{ width: '93%', height: 45, backgroundColor: '#eee', marginLeft: 10, marginTop: 20, display: 'flex', alignItems: 'center', paddingLeft: 10, fontSize: 16, color: '#000', fontFamily: 'FuturaLight', fontWeight: 'bold' }}>
                        Questions about fit? Email us at info@kridonvalley.com
                    </div>
                    <div style={{ marginTop: 20, border: '1px solid #ccc', borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 0, width: '95%', marginLeft: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 10 }}>
                        <div style={{ fontSize: 20, color: '#000', fontFamily: 'FuturaLight', fontWeight: 'bold' }}>DESCRIPTION</div>
                        <div onClick={toggleDropdown}><KeyboardArrowDownOutlinedIcon style={{ fontSize: 30, color: '#000', cursor: 'pointer' }} /></div>
                    </div>
                    {isOpen && (
                        <div style={{ marginLeft: 10, marginTop: 10, width: '95%' }}>
                            <p>{description}</p>
                        </div>
                    )}
                    <div style={{ marginTop: 10, border: '1px solid #ccc', borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 0, width: '95%', marginLeft: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 10 }}>
                        <div style={{ fontSize: 20, color: '#000', fontFamily: 'FuturaLight', fontWeight: 'bold' }}>MATERIALS</div>
                        <div onClick={toggleDropdown1}><KeyboardArrowDownOutlinedIcon style={{ fontSize: 30, color: '#000', cursor: 'pointer' }} /></div>
                    </div>
                    {isOpen1 && (
                        <div style={{ marginLeft: 10, marginTop: 10, width: '95%' }}>
                            <p>{material}</p>
                        </div>
                    )}
                    <div style={{ marginTop: 10, border: '1px solid #ccc', borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 0, width: '95%', marginLeft: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 10 }}>
                        <div style={{ fontSize: 20, color: '#000', fontFamily: 'FuturaLight', fontWeight: 'bold' }}>SHIPPING & RETURNS</div>
                        <div onClick={toggleDropdown2}><KeyboardArrowDownOutlinedIcon style={{ fontSize: 30, color: '#000', cursor: 'pointer' }} /></div>
                    </div>
                    {isOpen2 && (
                        <div style={{ marginLeft: 10, marginTop: 10, width: '95%' }}>
                            <p>This is the description content that is shown when the dropdown is open. You can add more detailed information about the topic here.</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ProductScreen