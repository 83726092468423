import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import { category } from './data';

function Category() {

    const navigation = useNavigate()

    const [screenSize, setScreenSize] = useState(getCurrentDimension());

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);


        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    return (
        <div style={{ width: '100%', height: screenSize.width < 600 ? 700 : 800, color: '#000', zIndex: 500, marginTop: screenSize.width < 600 ? 0 : 70 }}>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: screenSize.width < 600 ? 0 : 40 }}>
                <div style={{ width: '88%', display: 'flex', gap: 10, overflowX: 'scroll' }}>
                    {category.map((item, index) => (
                        <div style={{ width: screenSize.width < 600 ? 300 : 360, height: screenSize.width < 600 ? 600 : 670 }} key={index}>
                            <div style={{ width: screenSize.width < 600 ? 300 : 360, height: screenSize.width < 600 ? 500 : 540, backgroundColor: '#EAEEEF', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', borderRadius: 15 }}>
                                <img src={item.image} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </div>
                            <div style={{ fontSize: 20, fontFamily: 'FuturaLight', color: '#000', fontWeight: 'bold', marginTop: 10 }}>{item.name}</div>
                           
                            <div style={{ fontSize: 14, fontFamily: 'FuturaLight', color: '#000', fontWeight: 'bold', marginTop: 20, cursor: 'pointer' }} onClick={() => navigation('/Shop')}>SHOP NOW</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Category